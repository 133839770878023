<div class="layout-topbar"style="background-color: #d5e8ef;">
  <div class="topbar-left">
    <a tabindex="0" class="menu-button" (click)="app.onMenuButtonClick($event)">
      <i class="pi pi-chevron-left"></i>
    </a>
    <span class="topbar-separator"></span>

    <img id="app-logo" class="logo-image" src="assets/gallery/its.png" alt="Issue Tracking" title="Issue Tracking System"
      style="width: 40px; height: 40px; margin-right:1rem; float: left;" />
      <span class="viewname" style="text-transform: uppercase; font-size:14px;color: #495057;float: left;" *ngIf="HeaderText">
        <b>Issue Tracking System</b></span>      
<!-- 
    <img id="app-logo" class="logo-image" src="assets/gallery/its.png" alt="FactoryPRO-Logo"
      style="width: 45px; height: 45px; margin-top: 2px" />
    <h3 style="color: #495057">Issue Tracking</h3> -->
   
    <!-- <img id="logo-mobile" class="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" /> -->
  </div>

  <div class="topbar-left">
    <a class="logo">
      <img id="app-logo" class="logo-image" src="assets/gallery/Client-logo.png" alt="FactoryPRO-Logo"
        style="width: 80px; height: 59px; margin-left: -0.5rem" />
    </a>
  </div>
  <div class="topbar-right">
    <ul class="topbar-menu">     

      <li class="profile-item" [ngClass]="{ 'active-menuitem fadeInDown': app.topbarUserMenuActive }">
        <!-- <a href="#" (click)="app.onTopbarUserMenuButtonClick($event)">
          <img class="profile-image" src="" />
          <span class="profile-name">{{ firstName }}</span>
        </a> -->
        <!-- <img id="app-logo" class="logo-image" src="assets/gallery/Client-logo.png" alt="FactoryPRO-Logo"
        style="height:40px; width:55px; margin-right:1rem;float: left;padding-bottom: 5px;"/> -->
        <button pButton pRipple type="button" icon="pi pi-question" pTooltip="Help"tooltipPosition="top" 
        class="p-button-rounded p-button-info p-mr-2 p-mb-2" style="float:left;height:25px; width:25px;margin-top: 5px;" (click)="help()"></button>
        <a href="#" (click)="app.onTopbarUserMenuButtonClick($event)">
          <!-- <img src="assets/demo/images/avatar/profile.jpg" alt="diamond-layout" class="profile-image" /> -->
          <img *ngIf="!profileDisplayExist" src="assets/demo/images/avatar/Defaultprofile.jpg"
          alt="diamond-layout" class="profile-image" style="margin-left: 10px; object-fit: cover;"/>
          <img *ngIf="profileDisplayExist" src="{{profileDisplayPic}}"
          style="object-fit: cover;"  class="user-card-avatar" alt="diamond-layout" class="profile-image"/>
          
          <!-- <img src="{{profileDisplayPic}}"
              class="user-card-avatar" alt="diamond-layout" class="profile-image"/> -->
          <span class="profile-name">{{firstName}}</span>
        </a>
        <ul class="profile-menu fade-in-up">
         
          <li>
            <a (click)="LogoutClick()">
              <i class="pi pi-power-off"></i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </li>

      <li class="notifications-item" [ngClass]="{ 'active-menuitem ': app.topbarNotificationMenuActive }">
        <a href="#" tabindex="0" (click)="app.onTopbarNotificationMenuButtonClick($event)">
          <img id="grid-icon" src="assets/gallery/grid.png" alt="grid-icon" style=" height:30px; width:30px;padding:5px;"/>
        </a>
        <ul class="notifications-menu fade-in-up">
          <li role="menuitem">
            <div class="p-col-12">
              <div class="p-grid" style="width:488px;" *ngIf="TopMenuItemsList.length>9">
                <a (click)="TopMenuClicks(item.identifier,item.id)" *ngFor="let item of TopMenuItemsList">
                  <div class="p-col-12" style="text-align:center;" >
                    <div style="width:80px; height:80px;">
                      <img [src]="item.iconPath" alt="diamond-layout" style="width:60px; height:60px;" />
                      <br> <span style="text-align:center;">{{item.displayText}}</span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="p-grid" style="width:380px;" *ngIf="TopMenuItemsList.length>6&&TopMenuItemsList.length<=9">
                <a (click)="TopMenuClicks(item.identifier,item.id)" *ngFor="let item of TopMenuItemsList">
                  <div class="p-col-12" style="text-align:center;" >
                    <div style="width:80px; height:80px;">
                      <img [src]="item.iconPath" alt="diamond-layout" style="width:60px; height:60px;" />
                      <br> <span style="text-align:center;">{{item.displayText}}</span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="p-grid" *ngIf="TopMenuItemsList.length<=6">
                <a (click)="TopMenuClicks(item.identifier,item.id)" *ngFor="let item of TopMenuItemsList">
                  <div class="p-col-12 p-md-6 p-lg-6" style="text-align:center;" >
                    <div style="width:80px; height:80px;">
                      <img [src]="item.iconPath" alt="diamond-layout" style="width:60px; height:60px;" />
                      <br> <span style="text-align:center;">{{item.displayText}}</span>
                    </div>
                  </div>
                </a>
              </div>
             
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- <p-dialog header="Information !" [(visible)]="TopMenuDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}" >
  <hr>
  <div class="p-grid">
 
  <div class="p-col-12" style="text-align: center;">
   <span style="color:red; font-size:18px;">Please contact admin</span>
  </div>
  </div>
  </p-dialog> -->
  
  <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 1000;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
  </div>