import { Injectable } from "@angular/core";
import { HttputilityService } from "./httputility.service";
@Injectable({
  providedIn: "root",
})
export class AssignedToMeIssuesService {
  constructor(private _httputilityService: HttputilityService) {}
  
  getAssignedToMeIssuesData(data) {
    return this._httputilityService.Post('GetMyAssignedIssuesGridData',data);
  }
}
