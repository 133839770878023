import { Injectable } from "@angular/core";
import { HttputilityService } from "./httputility.service";
@Injectable({
  providedIn: "root",
})
export class IssueSearchService {
  constructor(private _httputilityService: HttputilityService) {}
  
  getPriorities() {
    return this._httputilityService.Get('GetPriorityMasterData');
  }

  getProducts() {
    //return this._httputilityService.GetP('Product/GetAllProducts');
    return this._httputilityService.Get('Product/GetAllProducts');
  }

  getIssueSearchData(data) {
    return this._httputilityService.Post('GetIssuesBySearch',data);
  }
  AddDocuments(data){
    return this._httputilityService.PostITMForm('AddDocuments',data);
  }
}
