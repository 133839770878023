import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpBackend } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BASE_API_URL_TOKEN } from '../injectors';
import { API_ENDPOINTS } from '../app.component';
@Injectable({
  providedIn: 'root',
})
export class HttputilityService {
  constructor(private http: HttpClient,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {}
  
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
   
  //Added for workflows
  public httpUOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };

  Get(routeUrl): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }

  GetP(routeUrl): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.PLMAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }

  Post(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.PLMAPI + routeUrl,
      data,
      this.httpOptions
    );
  }
  public httpOptionsFile = {
    headers: new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
    }),
  };
  PostITMForm(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.PLMAPI + routeUrl,
      data,
      this.httpOptionsFile
    );
  }
  
  PostU(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.UMSAPI + routeUrl,
      data,
      this.httpOptions
    );
  }
  
  //Added for Workflow
  AddU(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.UMSAPI + routeUrl,
      data,
      this.httpUOptions
    );
  }

  GetU(routeUrl, data?): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINTS.UMSAPI + routeUrl)
      .pipe(catchError(this.handleError));
  }

  Add(routeUrl, data) {
    return this.http.post<any>(
      API_ENDPOINTS.PLMAPI + routeUrl,
      data,
      this.httpOptions
    );
  }

  handleError(error) {
    return throwError(error.message);
  }
  AddAuthh(routeUrl, data) {
    return this.http.post<any>(API_ENDPOINTS.UMSAuthAPI + routeUrl, data, this.httpOptions);
  }
}
