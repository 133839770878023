import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class MyIssuesService {
  constructor(private _httputilityService: HttputilityService) {}
  
  getMyIssuesData(data) {
    return this._httputilityService.Post('GetMyIssuesGridData',data);
  }
}
