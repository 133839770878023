<div class="layout-dashboard">
  <div class="p-grid">
    <div class="p-col-12 p-md-12 p-xl-4">
      <div class="card no-gutter widget-overview-box widget-overview-box-1" style="height:auto;">
        <span class="overview-icon">
          <i class="pi pi-folder-open"></i>
        </span>
        <span class="overview-title">Reported By Me (My Issues)</span>
        <div class="p-grid overview-detail">
          <a class="p-col-4" style="cursor: pointer" (click)="ReportedTileClick('New')">
            <div class="overview-number">{{ dashBoardCountsReportedOpen }}</div>
            <div class="overview-subtext">New</div>
          </a>
          <a class="p-col-4" style="cursor: pointer" (click)="ReportedTileClick('In-Progress')">
            <div class="overview-number">{{ dashBoardCountsReportedInProgress }}</div>
            <div class="overview-subtext">In-Progress</div>
          </a>
          <a class="p-col-4" style="cursor: pointer" (click)="ReportedTileClick('In-Review')">
            <div class="overview-number">{{ dashBoardCountsReportedInReview }}</div>
            <div class="overview-subtext">In-Review</div>
          </a>

        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-xl-4">
      <div class="card no-gutter widget-overview-box widget-overview-box-3" style="height:155px;">
        <span class="overview-icon">
          <i class="pi pi-user-edit"></i>
        </span>
        <span class="overview-title">Assigned To Me</span>
        <div class="p-grid overview-detail">
          <a class="p-col-6" style="cursor: pointer" (click)="AssignedTileClick('New')">
            <div class="overview-number">{{ dashBoardCountsAssignedOpen }}</div>
            <div class="overview-subtext">New</div>
          </a>
          <a class="p-col-6" style="cursor: pointer" (click)="AssignedTileClick('In-Progress')">
            <div class="overview-number">{{ dashBoardCountsAssignedInProgress }}</div>
            <div class="overview-subtext">In-Progress</div>
          </a>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-xl-4">
      <div class="card no-gutter widget-overview-box widget-overview-box-4" style="height:155px;">
        <span class="overview-icon">
          <i class="pi pi-desktop"></i>
        </span>
        <span class="overview-title">Products</span>
        <div class="p-grid overview-detail">
          <a class="p-col-6" style="cursor: pointer" (click)="ProductReportedTileClick()">
            <div class="overview-number">{{ dashBoardCountsProductsReported }}</div>
            <div class="overview-subtext">Issues Reported</div>
          </a>
          <a class="p-col-6" style="cursor: pointer" (click)="ProductAssignedTileClick()">
            <div class="overview-number">{{ dashBoardCountsProductsAssigned }}</div>
            <div class="overview-subtext">Issues Assigned</div>
          </a>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-4 p-md-12 p-sm-12">
      <div class="card" style="height:400px;overflow-x: auto;">
        <div class="card-header" style="padding-bottom:0px;">
          <h4>My Issues By Status</h4>
        </div>
        <hr>
        <div class="p-grid p-formgrid">
          <div *ngIf="isChartDataAvailable;else Chartemptymessage;" class="revenue-chart-container"
          style="width: 400px;">
            <p-chart type="pie" id="revenue-chart" [data]="issueChart" [options]="options"></p-chart>
          </div>
          <ng-template #Chartemptymessage>
            <div style="text-align: center;margin-left: 15px;">No status found.</div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-4 p-md-12 p-sm-12">
      <div class="card" style="height:400px;overflow-x: auto;">
        <div class="card-header" style="padding-bottom:0px;">
          <h4>My Issues By Priority</h4>
        </div>
        <hr>
        <div class="p-grid p-formgrid">
          <div *ngIf="isPriorityChartDataAvailable;else PriorityChartemptymessage;" class="revenue-chart-container"
          style="width: 400px;">
            <p-chart type="pie" id="revenue-chart" [data]="priorityChart" [options]="options"></p-chart>
          </div>
          <ng-template #PriorityChartemptymessage>
            <div style="text-align: center;margin-left: 15px;">No Priority found.</div>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-lg-4 p-md-12 p-sm-12">
      <div class="card" style="height:400px; overflow-y: scroll;">
        <div class="card-header" style="padding-bottom:0px;">
          <h4>Timeline</h4>
          <p-dropdown [options]="TimelineOptions" (onChange)="GetTimeline($event.value)"
            [(ngModel)]="selectedTimelineOption" optionLabel="name" styleClass="dashbard-demo-dropdown"
            [ngStyle]="{'min-width':'8rem'}"></p-dropdown>
        </div>
        <hr>
        <div class="widget-timeline" *ngIf='isTimelineDataAvailable;else TimeLineemptymessage'>
          <div *ngFor="let item of Timeline;">
            <div class="timeline-event">
              <span class="timeline-event-icon" style="background-color: #64B5F6;">
                <i class="pi {{item.icon}}"></i>
              </span>
              <div class="timeline-event-title">{{item.title}}</div>
              <!-- <div class="timeline-event-detail" [innerHTML]="item.subTitleMod"></div> -->
              <div class="timeline-event-detail" (click)="issue(item)" [innerHTML]="item.subTitleMod"></div>

            </div>
          </div>
        </div>
        <ng-template #TimeLineemptymessage>
          <div style="text-align: center;">No timeline found.</div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">

      <h5 style="color:#495057; padding:15px 0 0 10px;width: 60%;">Recent Issues</h5> <!-- T0010003 -->
      <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">


        <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
          tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
          style="float:right;height:25px; width:25px;"></button>
        <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" pTooltip="Download"
          tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
          style="float:right; height:25px; width:25px;"></button>

      </span>
    </div>
    <div class="invoice invoice-header" id="invoice-content">
      <p-table #dt [value]="issueData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align" [scrollable]="true" scrollHeight="250px"
        styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
        [globalFilterFields]="['issueId','title','reportedBy','assignedTo','priority','status','product']">
        <ng-template pTemplate="header" let-columns class="invoice-items">
          <tr>
            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'issueCode':i==0, 'title': i == 1, 'createdByUserName':i==2, 'assignToUserName':i==3,'priority': i == 4, 
                  'statusName':i==5 ,'productName': i == 6}">
              {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th style="width:80px;">Actions</th>
          </tr>
          <tr id="trSearch">
            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
              <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                (input)="dt.filter($event.target.value, col.field, 'contains')"
                style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
            </th>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

              <td *ngIf="i == 0" style="text-align: left;padding-left:15px; width: 150px;">
                {{ rowData[col.field] }}
              </td>
              <td *ngIf="i == 1" style="text-align: left;padding-left:15px;  width: 300px;">
                {{ rowData[col.field] }}
              </td>
              <td *ngIf="i == 2" style="text-align: left;padding-left:15px; width: 150px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 3" style="text-align: left;padding-left:15px; width: 150px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 100px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 120px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 6" style="text-align: left;padding-left:15px; width: 220px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 7" style="text-align: left;padding-left:15px;width:80px;">
                {{rowData[col.field]}}
              </td>
            </ng-container>
            <td style="text-align: center;width:80px;">
              <button pButton type="button" (click)="issueDetails(rowData)" icon="pi pi-eye" pTooltip="View"
                class="p-button-raised p-button-primary" style="width:25px; height:25px;"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" style="text-align: left;">No records found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>