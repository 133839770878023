<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-grid p-fluid p-formgrid">
                <div class="p-field p-col-12 p-lg-12 p-md-12 p-sm-12">
                    <div class="card-header" id="cardheader"
                        style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto;">
                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">Issue Tracking System Help Guide</h5> 
                       <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                            <button *ngIf="showplus" pButton pRipple type="button" icon="pi pi-plus" pTooltip="Expand" (click)="showexpand1()"
                                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right;height:25px; width:25px;"></button>

                                <button *ngIf="showminus" pButton pRipple type="button" icon="pi pi-minus" pTooltip="Collapse" (click)="showexpand1()"
                                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right;height:25px; width:25px;"></button>                           
                        </span>
                    </div><br><br>
                    <!-- <div class="card-header">
                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">My Documents</h5>                        
                    </div> -->
                    <p-accordion>
                        <p-accordionTab header="Report An Issue" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#ReportAnIssue">
                                        <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                            <span class="overview-icon"><i class="pi pi-file"></i></span>
                                            <span class="overview-title">Report An Issue</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="My Issues" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#MyIssues">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">My Issues</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Assigned To Me" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#AssignedToMe">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Assigned To Me</span>
                                    </div>
                                    </a>
                                </div>                              
                            </div>                            
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Issue Search" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#IssueSearch">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Issue Search</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <p-accordion>
                        <p-accordionTab header="Reports" [selected]="showexpand" > 
                            <div class="p-grid p-fluid p-formgrid">
                                <div class="p-col-12 p-md-6 p-xl-3">
                                    <a href="#Reports">
                                    <div class="card no-gutter widget-overview-box widget-overview-box-1">
                                        <span class="overview-icon"><i class="pi pi-file"></i></span>
                                        <span class="overview-title">Reports</span>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>
        </div>

        <div class="card" style="min-height:600px;">
            <div class="p-grid p-formgrid p-fluid">
                <!-- <h3 style="margin-left: 20px;">My Documents</h3><br> -->
                <div id="ReportAnIssue">
                    <p style="font-size: large;margin-left: 20px;"><strong>Report An Issue:</strong> This is the main space where you can report an issue after identifying, which includes Issue reported date, Issue identified, Product and its Phase, Priority of the Issue, and HOLD option to put a hold on Product development until the issue gets resolved.</p>
                    <p style="font-size: large;margin-left: 20px;">Also, the issue can be assigned to a user from here, estimate hours, Title of the issue. The user can also explain the issue in detail in the Description area. Under the description, there is an option to attach a document or screenshot for verification, apart from the document notes that can be added.</p>
                    <img src="assets/gallery/reportanissue.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;" >
                    <br>                           
                    <p style="font-size: large;margin-left: 20px;">User can write a description as shown in the below image.</p>
                    <img src="assets/gallery/reportanissue1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;" >
                    <br> 
                    <p style="font-size: large;margin-left: 20px;">User can also attach the required attachments as shown below.</p>
                    <img src="assets/gallery/reportanissue2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;" >
                    <br> 
                    <p style="font-size: large;margin-left: 20px;">User can also add the link issues as shown below.</p>
                    <img src="assets/gallery/reportanissue3.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;" >
                    <br> 
                    <p style="font-size: large;margin-left: 20px;">User can also notify the issues to the other users as shown below.</p>
                    <img src="assets/gallery/reportanissue4.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;" >
                    <br> 
                    <img src="assets/gallery/reportanissue5.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;" >
                    <br> 
                    <p style="font-size: large;margin-left: 20px;">User can also add notes as shown below.</p>                
                    <img src="assets/gallery/reportanissue6.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;" >
                    <br><br>
                </div>
                <div id="MyIssues">
                    <p style="font-size: large;margin-left: 20px;"><strong>My Issues:</strong> From here, the user can see the issue reported him/her along with the title, priority, issue assigned to, and status.</p>
                    <img src="assets/gallery/myissues.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>
                    <p style="font-size: large;margin-left: 20px;">If you click on the view button, you can see the full information about the issue; The details page is almost the same as the “Report an issue” page, but it is filled with information that was shown in the list. This information can be edited by the logged-in user according to the action.</p>
                    <img src="assets/gallery/myissues1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>  
                    <p style="font-size: large;margin-left: 20px;">Added notes will appear as shown below.</p>                        
                    <img src="assets/gallery/myissues2.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>
                <div id="AssignedToMe">
                    <p style="font-size: large;margin-left: 20px;"><strong>Assigned To Me:</strong> On this page, A list of the Issues assigned to the logged-in user by other users are provided; these issues can be searched with provided filters like from and to date, Issues by their status, and product. </p>
                    <p style="font-size: large;margin-left: 20px;">This is a complete report of the Issues that the logged-in user handles or handled. The status filter has “New”, “In-Progress”, “Resolved”, “In-Review”, and “Closed” options which provide individual data according to the selection to review.</p>
                    <img src="assets/gallery/assignedtome.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>     
                <div id="IssueSearch">
                    <p style="font-size: large;margin-left: 20px;"><strong>Issue Search:</strong> On this page, a list of the Issues reported by the logged-in user and the issues assigned to other users can be seen. Also, these issues can be searched with provided filters like “from and to date”, “Issues by their status”, “title”, and “product”. </p>
                    <p style="font-size: large;margin-left: 20px;">This is a complete report of the Issues that the logged-in user assigned and wants to review the status of the issue. The status filter has New, In-Progress, Resolved, In-Review, and Closed options which provide individual data according to the selection to review. </p>
                    <img src="assets/gallery/issuesearch.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br><br>
                </div>
                <div id="Reports">
                    <p style="font-size: large;margin-left: 20px;"><strong>Reports:</strong> On this page, we can see the report of all issues irrespective of the assigned or reported by whom by selecting from the given dropdown options, which includes Period, Product line, and Product Engineer.</p>
                    <p style="font-size: large;margin-left: 20px;">It’s not a mandatory field to search with these options as a default user can see the report generated for the current week for all product lines and all products. engineers.</p>
                    <img src="assets/gallery/reports.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>                     
                    <p style="font-size: large;margin-left: 20px;">If the logged-in user is particular about any product line or product engineer or any time period, then the user can go through the above options.</p>
                    <p style="font-size: large;margin-left: 20px;">From the below image, it can be seen that the user has selected The current month to search the report, but no Product line and No Product Engineer was selected so the issue has been presented here as a report.</p>
                    <img src="assets/gallery/reports1.jpg" style="width: 55%;height:auto;display: block;margin-left: 20%;">
                    <br>                      
                    <p style="font-size: large;margin-left: 20px;">According to the selection, data is presented in detail with pie charts and table grids. Here you can see 3 pie charts, 1st chart explains the issues by their status, 2nd one describes Issues as per their priority, and the 3rd one is by their phase.</p> 
                    <br><br>
                </div>          
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-10"></div>
            <div class="p-field p-col-12 p-md-2">
            <a href="#cardheader" >
                <button pButton pRipple type="button" icon="pi pi-arrow-up" pTooltip="Go to Top"
                tooltipPosition="top" class="p-button-rounded p-button-info p-mr-2 p-mb-2"
                style="float:right;height:25px; width:25px; "></button>
                </a>        
        </div>  
    </div>
    </div>
</div>

