import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private _httputilityService: HttputilityService) {}
  
  GetUserDetails(userGuid){
    return this._httputilityService.GetU("user/GetUserDetails?UserGUID=" + userGuid );
  }
  
  getDashboardCounts(userID) {
    return this._httputilityService.Get("GetDashboardSummary_ITS?userId=" + userID);
  }
  
  getChartData(userId) {
    return this._httputilityService.Get("GetChartData_ITS?userId=" + userId);
  }

  getPriorityChartData(userId) {
    return this._httputilityService.Get("GetPriorityChartData?userId=" + userId);
  }

  getDashboardTimelineData(userId,type) {
    return this._httputilityService.Get("GetTimeLineData?userId="+userId+"&timeLineType="+type);
  }

  GetDashboardTableData30Days(userId){
    return this._httputilityService.Get("GetDashboardTableData?userId=" + userId );
  }
   UpdateSecurityToken(token){
    return this._httputilityService.PostU("user/UpdateSecurityToken?Token=" + token ,null);
  }
  CreateSecurityToken(){
    return this._httputilityService.PostU("user/CreateSecurityToken" ,null);
  }
  GetMenusByUser(userId,applicationid){
    return this._httputilityService.GetU('GetMenusByUser?userid='+userId+'&applicationid='+applicationid);
  }
  AddUserTracking(model){
    return this._httputilityService.PostU('user/AddUserTracking',model);
  }
}
