import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../facade/facade.service';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem, SortEvent} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  userId = sessionStorage.getItem('userGuid');

  statusChartlabels: number[] = [];
  statusChartdata: number[] = [];

  statusChart:any;

  priorityChartlabels: number[] = [];
  priorityChartdata: number[] = [];

  priorityChart:any;

  line: any[];

  customPeriod:boolean = false;
  selectedFromDate:any;
  selectedToDate:any;

  selectedLine:any[]=[];

  productChartlabels: number[] = [];
  productChartdata: number[] = [];

  phaseChart:any;

  status: SelectItem[];
  pageTitle: string = 'Create Document';
  cols: any[];
  issues:any;
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  searchResultsData:any;
  reportedbyUsers:any[]=[];
  tempreportedbyUsers:any[]=[];
  selectedreportedbyChecked:any[]=[];
  selectedUser:any[]=[];
  periods: ({ Id: string; Name: string } | { Id: number; Name: string })[];
  periodselect: any;
  piedata:any;
  piedata2:any;
  piedata3:any;
  options: any;
  isStatusChartDataAvailable:boolean;
  isPriorityChartDataAvailable:boolean;
  isPhaseChartDataAvailable:boolean;

  constructor(
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {  
    
    this.selectedToDate=new Date();
    
    let dte = new Date();
    dte.setMonth(dte.getMonth() - 1);
    this.selectedFromDate= new Date(dte.toString());

      this.cols = [
        { field: 'issueCode', header: 'Issue Id', dynamicPlaceHolder: 'Search' },
        { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search' },
        { field: 'statusName', header: 'Status', dynamicPlaceHolder: 'Search' }, 
        { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search' },     
        { field: 'createdByUserName', header: 'Reported By', dynamicPlaceHolder: 'Search' },  
        { field: 'createdDate', header: 'Reported Date', dynamicPlaceHolder: 'Search' },  
        { field: 'assignToUserName', header: 'Assigned To', dynamicPlaceHolder: 'Search' },
        { field: 'productName', header: 'Product', dynamicPlaceHolder: ' Search' }  
      ];

    this.piedata = {
      labels: ['New', 'In-Progress', 'Resolved', 'In-Review', 'Closed'], 
      datasets: [
        {
          data: [20, 20, 15, 25, 20],
          backgroundColor: [
            '#FBC02D',
            '#2196F3',
            '#689F38',
            '#607D8B',
            '#FF0000',            
            ],
          
        },
      ],
    };
    this.piedata2 = {
      labels: ['Low', 'Medium', 'High', 'Critical'], 
      datasets: [
        {
          data: [30, 20, 25, 25],
          backgroundColor: [
              '#66cc33',
              '#ff9933',
              '#FF0000',
              '#990000',
            ],
          
        },
      ],
    };
    this.piedata3 = {
      labels: ['Jazzman', 'Geras ', 'Vela'], 
      datasets: [
        {
          data: [35, 35, 30],
          backgroundColor: [
            '#FBC02D',
            '#2196F3',
            '#689F38',
            ],
          
        },
      ],
    };
    this.options = {
      legend: { display: true, position:'right', align:'center', 
      labels: { fontColor: "#7b7c7c",fontSize: 14,padding:20}
    },
  };

    this.periods = [     
      {
        Id: 0,
        Name: "Current Week",
      },
      {
        Id: 1,
        Name: "Previous Week",
      },
      {
        Id: 2,
        Name: "Current Month",
      },
      {
        Id: 3,
        Name: "Previous Month",
      },
      {
        Id: 4,
        Name: "Current Quarter",
      },
      {
        Id: 5,
        Name: "Previous Quarter",
      },
      {
        Id: 6,
        Name: "Custom",
      },
    ]; 
    

    this.periodselect =  { Id: 0, Name: "Current Week" }; 

    this.userId = this.userId.toUpperCase();

    this.GetProductLne();
    
    this.getProductEngineerUsers();  
  }

  selectedreportedbyChange(selectedreportedby){
    console.log(selectedreportedby);
    this.selectedreportedbyChecked=[];
    selectedreportedby.value.forEach((value, key) => {
      this.selectedreportedbyChecked.push(value);
    });
  }  

  GetProductLne() {
    this._facadeService.DataSourceAsQuery("Select * from tblProductLine")
    .subscribe((data) => {
      this.line = data.returnObject;
      this.line.forEach((ln) => {
        this.selectedLine.push(ln);
      });
    });
}

  getProductEngineerUsers(){
    this.showLoading = true;
    
    this._facadeService.getPhaseUsers('', '').subscribe(
      (data) => {
        
        this.tempreportedbyUsers = data.returnObject;
        console.log(this.tempreportedbyUsers);   
        
        this.tempreportedbyUsers.forEach(el => {
          this.reportedbyUsers.push({
             label: el.fullName, value: el.userGuid
          });
          //this.selectedUser.push(el.userGuid)
          //this.selectedreportedbyChecked.push(el.userGuid);
       });

       this.getReportSearchIssueData();

      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getReportSearchIssueData(){

    var lm = [];
    this.selectedLine.forEach((element) => {
      lm.push(element.id);
      });

      var UtcFromDate = new Date(this.selectedFromDate).toUTCString();
    var UtcToDate = new Date(this.selectedToDate).toUTCString();
   
    var data={
      UserId:this.userId,
      productEngineerUsers:this.selectedreportedbyChecked,      
      period:this.periodselect.Name,
      productLineID: lm,
      FromDate:this.datePipe.transform(this.selectedFromDate, "MM/dd/yyyy",'UTC +4'),
      ToDate:this.datePipe.transform(this.selectedToDate, "MM/dd/yyyy",'UTC +4'),
    }     
    

    this.searchResultsData = [];
    
    this.statusChartlabels = [];
    this.statusChartdata = [];

    this.priorityChartlabels = [];
    this.priorityChartdata = [];

    this.productChartlabels = [];
    this.productChartdata = [];

    this.isStatusChartDataAvailable = false;
    this.isPriorityChartDataAvailable = false;
    this.isPhaseChartDataAvailable = false;


    this.showLoading = true;
    this._facadeService.getReportSearchIssueData(data).subscribe(
      (data) => {        
        if(data.returnObject!=null && data.returnObject.searchIssues!=null){         
          
          data.returnObject.searchIssues.forEach((value, key) => {
           let convertactiondate = new Date(value.createdDate+'Z') 
            value.createdDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
            value.createdDate=this.datePipe.transform(value.createdDate,'MM/dd/yyyy');
             this.searchResultsData.push(value);
            
          });
        }
        //console.log("Report Search Issues data:"+JSON.stringify(data.returnObject.searchIssues));

        // Binding Status Chart Data  - - Start
        if(data.returnObject.statusChartData!=null){    

        for (let i = 0; i < data.returnObject.statusChartData.length; i++) {
          this.statusChartlabels.push(data.returnObject.statusChartData[i].status);
        }
        for (let i = 0; i < data.returnObject.statusChartData.length; i++) {
          this.statusChartdata.push(data.returnObject.statusChartData[i].count);
          if(data.returnObject.statusChartData[i].count>0){
            this.isStatusChartDataAvailable = true;
          }
        }
        //console.log("Report Status chart data :: " + JSON.stringify(data.returnObject.statusChartData));
        this.statusChart = {
          labels: this.statusChartlabels,
          datasets: [
            {
              data: this.statusChartdata,
              backgroundColor: [
                '#FBC02D',
                '#2196F3',
                '#689F38',
                '#FF0000',
                '#607D8B',
                ],
            },
          ],
        };
  
        this.options = {
          legend: { display: true, position:'right', align:'center', 
          labels: { fontColor: "#7b7c7c",fontSize: 12}
        },
      }
    }

      // Binding Status Chart Data  - - End

      // Binding Priority Chart Data  - - Start

      if(data.returnObject.priorityChartData!=null){   
      for (let i = 0; i < data.returnObject.priorityChartData.length; i++) {
        this.priorityChartlabels.push(data.returnObject.priorityChartData[i].status);
      }
      for (let i = 0; i < data.returnObject.priorityChartData.length; i++) {
        this.priorityChartdata.push(data.returnObject.priorityChartData[i].count);
        if(data.returnObject.priorityChartData[i].count>0){
          this.isPriorityChartDataAvailable = true;
        }
      }
      //console.log("Report Priority chart data :: " + JSON.stringify(data.returnObject.priorityChartData));
      this.priorityChart = {
        labels: this.priorityChartlabels,
        datasets: [
          {
            data: this.priorityChartdata,
            backgroundColor: [
              '#66cc33',
              '#ff9933',
              '#FF0000',
              '#990000',
              ],
          },
        ],
      };

      this.options = {
        legend: { display: true, position:'right', align:'center', 
        labels: { fontColor: "#7b7c7c",fontSize: 12}
      },
    }
  }

    // Binding Priority Chart Data  - - End

     // Binding Product Chart Data  - - Start
     
     if(data.returnObject.productChartData!=null)
     {
      for (let i = 0; i < data.returnObject.productChartData.length; i++) {
        this.productChartlabels.push(data.returnObject.productChartData[i].status);
      }
      for (let i = 0; i < data.returnObject.productChartData.length; i++) {
        this.productChartdata.push(data.returnObject.productChartData[i].count);
        if(data.returnObject.productChartData[i].count>0){
          this.isPhaseChartDataAvailable = true;
        }
      }
     // console.log("Report Product chart data :: " + JSON.stringify(data.returnObject.productChartData));
      this.phaseChart = {
        labels: this.productChartlabels,
        datasets: [
          {
            data: this.productChartdata,
            backgroundColor: [
              '#FBC02D',
              '#2196F3',
              '#689F38',
              '#FF0000',
              '#607D8B'
              ],
          },
        ],
      };

      this.options = {
        legend: { display: true, position:'right', align:'center', 
        labels: { fontColor: "#7b7c7c",fontSize: 12}
      },
    }
  }

    // Binding Product Chart Data  - - End

        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );

  }

  ViewMyIssue(rowdata){    
    location.href = this.baseUrl["API_ENDPOINTS"]["ITMUI"]+'editissue?issueId='+rowdata.issueGuid + '&frm=r';   
}

  print() {
    window.print();
  }
  
  exportExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 90 },{ wpx : 500 },{ wpx : 120 },{ wpx : 110 },{ wpx : 90 },{ wpx : 120 },{ wpx : 100 },{ wpx : 90 }];
    /* save to file */
    XLSX.writeFile(wb, "RecentIssues.xlsx");
 }
 delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['I1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
 ec(r, c) {
   return XLSX.utils.encode_cell({r:r,c:c})
 }

  SetCustomPeriod(){
    if(this.periodselect.Name == 'Custom'){
      this.customPeriod = true;
    }
    else{
      this.customPeriod = false;
    }
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
  
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (event.field === 'createdDate') {
        // Convert the string dates to Date objects for correct sorting
        const date1 = value1 === '--' ? null : new Date(value1);
        const date2 = value2 === '--' ? null : new Date(value2);
  
        // Handle special case: treat "--" as greater or smaller than any date
        if (date1 === null) {
          result = date2 === null ? 0 : -1;
        } else if (date2 === null) {
          result = 1;
        } else {
          result = date1.getTime() - date2.getTime();
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
  
      return (event.order * result);
    });
  
    event.data.forEach(e => {
      e.createdDate = this.datePipe.transform( e.createdDate, 'MM/dd/yyyy');
    });
  }
}




