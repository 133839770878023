<div class="login-body">
  <div class="login-wrapper">
    <div class="login-image">
      <div class="login-image-content" style="text-align: center">
        <img
          src="assets/gallery/Client-logo.png"
          class="logo"
          alt="FactoryPRO-Logo"
          style="width: 200px; height: 120px"
        />
        <!-- <h4>Lorem ipsum dolor sit amet, consectetur
                <br/> adipiscing elit. Donec posuere velit nec enim
                <br/> sodales, nec placerat erat tincidunt. </h4> -->
      </div>
      <img src="assets/gallery/c11.png" class="logo" alt="FactoryPRO-Logo" />

      <div class="p-grid" style="text-align: center">
        <div class="col-md-1"></div>
        <div class="col-md-2">
          <img src="assets/gallery/proj.png" class="img-fluid" alt="image" />
          <p>
            Project<br />
            Management
          </p>
        </div>
        <div class="col-md-2">
          <img src="assets/gallery/doc.png" class="img-fluid" alt="image" />
          <p>
            Document <br />
            Management
          </p>
        </div>
        <div class="col-md-2">
          <img src="assets/gallery/prod.png" class="img-fluid" alt="image" />
          <p>
            Product <br />
            Management
          </p>
        </div>
        <div class="col-md-2">
          <img src="assets/gallery/proj.png" class="img-fluid" alt="image" />
          <p>
            Project<br />
            Management
          </p>
        </div>
        <div class="col-md-2">
          <img src="assets/gallery/doc.png" class="img-fluid" alt="image" />
          <p>
            Document<br />
            Management
          </p>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>

    <div class="login-panel">
      <div class="login-form" style="margin-top: 100px; width: 100%">
        <img
          src="assets/gallery/logo.png"
          class="logo"
          alt="FactoryPRO-Logo"
          style="width: 140px; height: 80px"
        />
        <!-- <h2>Create Your Free Account</h2>  -->
        <h2>Login Here</h2>
        <!-- <p>Already have an account? <a href="#">Login</a></p> -->
        <input
          pInputText
          id="name"
          placeholder="UserName"
          [(ngModel)]="userName"
        />

        <!-- <input pInputText id="email" placeholder="Email" /> -->
        <input
        type="password"
          pPassword
          id="password"
          placeholder="Password"
          [(ngModel)]="password" [feedback]="false"
        />
        <button pButton label="Login" type="button" (click)="login()"></button>
        <br />
        <small id="errorMessage" class="p-invalid" *ngIf="loginFlag">{{
          loginMessage
        }}</small
        ><br />
        <!-- <p><a href="#">Forgot Password ?</a></p> -->
      </div>

      <!-- <p>A problem? <a href="#">Click here</a> and let us help you.</p> -->
    </div>
  </div>
</div>
