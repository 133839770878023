import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from 'src/app/services/printcommon.service';

@Component({
  selector: 'app-myissues',
  templateUrl: './myissues.component.html',
  styleUrls: ['./myissues.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyissuesComponent implements OnInit {
  userId = sessionStorage.getItem('userGuid');
  status: SelectItem[];
  pageTitle: string = 'My Issue';
  cols: any[];
  selectedFromDate:any;
  selectedToDate:any;
  minDate:any;
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  myIssuesData:any;
  selectedStatus:any[]=[];
  selectedStatusChecked:any[]=[];
  product:any;
  selectedProduct: any;
  productsData: any;
  productID:any;
  filteredProduct: any[];
  isProductRelated:any;

  constructor(
    private _facadeService: FacadeService,public printService: PrintcommonService,
    private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) { }

  ngOnInit(): void {
    
    this.selectedToDate=new Date();
    this.getProducts();
    let dte = new Date();
    dte.setMonth(dte.getMonth() - 1);   

    this.selectedFromDate= new Date(dte.toString());
      this.cols = [
        { field: 'issueCode', header: 'Issue Id', dynamicPlaceHolder: 'Search' },
        { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search' },
        { field: 'assignToUserName', header: 'Assigned To', dynamicPlaceHolder: 'Search' },
        { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search' },     
        { field: 'statusName', header: 'Status', dynamicPlaceHolder: 'Search' },       
        { field: 'productNPhase', header: 'Product/Phase', dynamicPlaceHolder: ' Search' },      
      ];
  
      this.status = [ 
        {label: 'New', value: {id: 0, name: 'New', code: 1}},
        {label: 'In-Progress', value: {id: 1, name: 'In-Progress', code: 2}},
       {label: 'Resolved', value: {id: 2, name: 'Resolved', code: 3}},
        {label: 'In-Review', value: {id: 3, name: 'In-Review', code: 4}},
        {label: 'Closed', value: {id: 4, name: 'Closed', code: 5}},
        {label: 'Re-Open', value: {id: 5, name: 'Re-Open', code: 7}},
    ];

    
      this.userId = this.userId.toUpperCase();

    var isFromDashboardTileStatus = this.route.snapshot.queryParamMap.get('status');
    this.isProductRelated = this.route.snapshot.queryParamMap.get('isProductRelated');
    if(isFromDashboardTileStatus!=null && isFromDashboardTileStatus!="" && isFromDashboardTileStatus!=undefined){
      var filteredStatus = this.status.find(m=>m.label==isFromDashboardTileStatus);
      this.selectedStatus.push(filteredStatus.value);
      this.selectedStatusChecked.push(filteredStatus.value.code);
    }
    else{
      this.selectedStatus.push(this.status[0].value,this.status[1].value,this.status[2].value,this.status[3].value, this.status[5].value)
      this.selectedStatusChecked.push(1,2,3,4,7);
    }
    this.getMyIssuesData();
    
  }

  ViewMyIssue(rowdata){    
    location.href = this.baseUrl["API_ENDPOINTS"]["ITMUI"]+'editissue?issueId='+rowdata.issueGuid;  
  }

  filterProducts(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.productsData.length; i++) {
        let doctype = this.productsData[i];
        if (doctype.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(doctype);
        }
    }    
    this.filteredProduct = filtered;
}

  getProducts() {
    this.showLoading = true;
    this._facadeService.getProducts().subscribe(
      (data) => {
        //this.showLoading = false;
        this.productsData = data.returnObject;
        //console.log(this.productsData);
        //console.log("Document Identifier ::" + JSON.stringify(this.productID));       
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  selectedStatusChange(selectedStatus){
    this.selectedStatusChecked=[];
    selectedStatus.value.forEach((value, key) => {
      this.selectedStatusChecked.push(value.code);
    });
  }

  getMyIssuesData() {
    this.myIssuesData=[];
    var UtcFromDate = new Date(this.selectedFromDate).toUTCString();
    var UtcToDate = new Date(this.selectedToDate).toUTCString();
    var data={
      UserId:this.userId,
      statusIds:this.selectedStatusChecked,
      FromDate:this.datePipe.transform(this.selectedFromDate, "MM/dd/yyyy",'UTC +4'),
      ToDate:this.datePipe.transform(this.selectedToDate, "MM/dd/yyyy",'UTC +4'),
      productId:null,
      IsProductRelated: false 
    }

    if(this.selectedProduct!=null){
      data.productId = this.selectedProduct.productID
    }
    if(this.isProductRelated=="1"){
      data.IsProductRelated = true;
    }

    this.showLoading = true;
    this._facadeService.getMyIssuesData(data).subscribe(
      (data) => {
        if(data.returnObject!=null){
          data.returnObject.forEach((value, key) => {
           let convertactiondate = new Date(value.createdAt+'Z') 
            value.createdAt =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
             this.myIssuesData.push(value);
          });
        }
       // console.log("My Issues data:"+JSON.stringify(data.returnObject));
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'My Issues','trSearch',true);
  }
exportExcel() {
   /* table id is passed over here */   
   let element = document.getElementById('excel-table'); 
   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
   /* generate workbook and add the worksheet */
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
   this.delete_row(wb.Sheets.Sheet1, 1);
   ws['!cols'] = [{ wpx : 90 },{ wpx : 500 },{ wpx : 120 },{ wpx : 110 },{ wpx : 90 },{ wpx : 120 }];
   /* save to file */
   XLSX.writeFile(wb, "My Issues.xlsx");
}
delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['G1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}

}
