import { Injectable } from "@angular/core";
import { LoginService } from "../services/login.service";
import { DashboardService } from "../services/dashboard.service";
import { MyIssuesService } from "../services/myissues.service";
import { AssignedToMeIssuesService } from "../services/assignedtomeissues.service";
import { IssueSearchService } from "../services/issuesearch.service";
import { ReportService } from "../services/report.service";
@Injectable({
  providedIn: "root",
})
export class FacadeService {
  constructor(
    private _loginService: LoginService,
    private _dashboardService: DashboardService,
    private _myIssuesService: MyIssuesService,
    private _assignedtomeIssuesService: AssignedToMeIssuesService,
    private _issuesearchService: IssueSearchService,
    private _reportService: ReportService
  ) {}
  login(model) {
    return this._loginService.login(model);
  }
  
  getDashboardCounts(userId) {
    return this._dashboardService.getDashboardCounts(userId);
  }

  getChartData(userId) {
    return this._dashboardService.getChartData(userId);
  }

  getPriorityChartData(userId) {
    return this._dashboardService.getPriorityChartData(userId);
  }

  getDashboardTimeline(userId,type) {
    return this._dashboardService.getDashboardTimelineData(userId,type);
  }

  GetDashboardTableData30Days(userId){
    return this._dashboardService.GetDashboardTableData30Days(userId);
  }

  GetUserDetails(userGuid){
    return this._dashboardService.GetUserDetails(userGuid); 
  }

  getMyIssuesData(data) {
    return this._myIssuesService.getMyIssuesData(data);
  }

  getAssignedToMeIssuesData(data) {
    return this._assignedtomeIssuesService.getAssignedToMeIssuesData(data);
  }

  getPriorities() {
    return this._issuesearchService.getPriorities();
  }

  getProducts() {
    return this._issuesearchService.getProducts();
  }

  getIssueSearchData(data) {
    return this._issuesearchService.getIssueSearchData(data);
  }

  getReportedByUsers() {
    return this._reportService.getReportedByUsers();
  }

  getReportSearchIssueData(data) {
    return this._reportService.getReportSearchIssueData(data);
  }

  getPhasesByProductId(productId) {
    return this._reportService.getPhasesByProductId(productId);
  }

  getPhaseUsers(productGuid, phaseName) {
    return this._reportService.getPhaseUsers(productGuid, phaseName);
  }

  getAllUsers() {
    return this._reportService.getAllUsers();
  }

  getUserDetailedData(userIds) {
    return this._reportService.getUserDetailedData(userIds);
  }

  CreateIssue(requestData) {
    return this._reportService.createIssue(requestData);
  }

  SendIssueCreationEmails(IssueCode) {
    return this._reportService.SendIssueCreationEmails(IssueCode);
  }

  UpdateIssue(requestData) {
    return this._reportService.updateIssue(requestData);
  }

  SendIssueUpdateEmails(IssueCode) {
    return this._reportService.SendIssueUpdateEmails(IssueCode);
  }

  getIssueCodes() {
    return this._reportService.getIssueCodes();
  }

  getIssueDetailsById(issueId) {
    return this._reportService.getIssueDetailsById(issueId);
  }

  getIssueHistory(issueId) {
    return this._reportService.getIssueHistory(issueId);
  }

  DataSourceAsQuery(datasource){
    return this._reportService.DataSourceAsQuery(datasource);
  }

  deleteDocument(model) {
    return this._reportService.deleteDocument(model);
  }

  saveIssueNote(note, issueId, userId) {
    return this._reportService.saveIssueNote(note, issueId, userId);
  }

  CreateSecurityToken(){
    return this._dashboardService.CreateSecurityToken();
  }
  UpdateSecurityToken(token){
    return this._dashboardService.UpdateSecurityToken(token);
  }
  AddDocuments(data){
    return this._issuesearchService.AddDocuments(data);
  }
  GetMenusByUser(userId,applicationid){
    return this._dashboardService.GetMenusByUser(userId,applicationid);
  }
  AddUserTracking(model){
    return this._dashboardService.AddUserTracking(model);
  }
}
