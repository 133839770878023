import { Component, Inject,  OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {EditorModule} from 'primeng/editor';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { FileUpload } from "primeng/fileupload";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { HttpClient } from "@angular/common/http";
import {  
  ViewDocuments,
  DeleteModel,
} from "../../models/IssueDetails";
import { API_ENDPOINTS } from 'src/app/app.component';

@Component({
  selector: 'app-editissue',
  templateUrl: './editissue.component.html',
  styleUrls: ['./editissue.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditIssueComponent implements OnInit {
  description: string = '<div>Enter Description</div>'; 
  userId = sessionStorage.getItem('userGuid');
  applicationID = sessionStorage.getItem("applicationID"); //"92F7C866-307B-4FED-8931-7A9788891C60";//
  UserDepartmentID = sessionStorage.getItem("userDepartmentID"); //'EEC9D76A-39D1-4772-8FE5-064B00E4DC6B';//  
  UserName = sessionStorage.getItem("firstName");
  status: SelectItem[];
  priority: SelectItem[];
  pageTitle: string = 'Edit An Issue';
  selectedStatus: any;
  TempselectedStatus: any;
  showLoading:boolean;
  
  fromPage:any;
  
  product:any;
  selectedProduct: any;
  productsData: any;
  productID:any;
  filteredProduct: any[];

  phasesData: any;
  selectedPhase: any;
  phaseID:number;
  assignToUserId:any;
  filteredPhase: any[];

  usersData: any;
  selectedUser: any;
  
  filteredUser: any[];

  selectedPriority:any;

  titleError: any;
  assignedToError:any;
  issueDateError:any;
  issueDueDateError:any;
  issueSuccessMessage:any;
  statusError:any;

  isValidationFailed:boolean = false;
  messageDisplay:boolean= false;

  WatchersDisplay: boolean= false;
  popupWatcherEmailId:any;

  title:any;
  issueCode: any;
  defaultCurrDate: any;
  selectedIssueDate: any;
  selectedDueDate: any;
  estimatedEffort:any;
  actualEffort:any;
  isHold:boolean;
  id:number;
  issueId:any;  
  cols: any[]; 
  cols1: any[];
  cols2: any[];  
  cols3: any[]; 
  historycols:any[];
  index: number = 0;
  issueData:any=[];
  addDisplay: boolean;

  watchercols: any[];
  WatchersData:any=[];

  prioritiesData: any;

  // Documents
  addDocsDisplay: boolean;
  ViewAddedDocuments: ViewDocuments[];
  selectedViewAddedDocuments: ViewDocuments[];
  @ViewChild("dtViewDocuments") tableViewDocuments: Table;
  confirmDeleteDocument = false;
  DocsError: string;
  RemoveID: number;
  DeleteModel: DeleteModel;
  popuptype: string;
  apiResponse: any;
  selectedDoc: any[];
  @ViewChild("fileInput") fileInput: FileUpload;
  @ViewChild("fubauto") fubauto: FileUpload;
  DMSAPIUURL:string = API_ENDPOINTS.PLMAPI;

   // Notes
  notesList: any[] = [];
  notesExist: boolean = false;
  issueNote:any;
  noteDocDisplay: boolean = false; 
  ViewNoteDocuments: ViewDocuments[];
  @ViewChild("fileInput1") fileInput1: FileUpload;
  addedNoteDoc: any;

  // Watchers
  selectedWatcher:any[]=[];
  selectedWatcherChecked:any[]=[];
  WatcherDDLUsers:any[]=[];
  tempWatcherUsers:any[]=[];
  allWatcherUsers:any[]=[];
  WatchersList:any[];

  //Linked Issues
  addLIPDisplay:boolean;
  selectedLIFromDate:any;
  selectedLIToDate:any;
  selectedLIStatus:any[]=[];
  selectedLIStatusChecked:any[]=[];
  LIissueTitle:any;
  selectedLIProduct: any;
  filteredLIProduct: any[];
  LIissuesData:any;
  LinkedIssuesData:any;
  LinksList: any[];

  isViewMode:boolean;

  // History
  HistoryIssuesData:any;

  constructor( private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private http: HttpClient,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) { }

  ngOnInit(): void {     
    
    
    this.watchercols = [
      { field: 'WatcherEmailId', header: 'Watcher Email ID'}  
    ];

    this.status = [ 
      {label: 'New', value: {id: 0, name: 'New', code: 1}},
      {label: 'In-Progress', value: {id: 1, name: 'In-Progress', code: 2}},
     {label: 'Resolved', value: {id: 2, name: 'Resolved', code: 3}},
      {label: 'In-Review', value: {id: 3, name: 'In-Review', code: 4}},
      {label: 'Closed', value: {id: 4, name: 'Closed', code: 5}},
      {label: 'Re-Open', value: {id: 5, name: 'Re-Open', code: 7}},
  ];

  this.priority = [ 
    {label: 'Low', value: {id: 0, name: 'Low', code: 1}},
    {label: 'Medium', value: {id: 1, name: 'Medium', code: 2}},
   {label: 'High', value: {id: 2, name: 'High', code: 3}},
    {label: 'Critical', value: {id: 3, name: 'Critical', code: 4}},
];

this.cols = [
  { field: 'documentName', header: 'Document Name', dynamicPlaceHolder: 'Search' },
  { field: 'uploadedby', header: 'Uploaded By', dynamicPlaceHolder: 'Search' }, 
  { field: 'uploadedon', header: 'Uploaded On', dynamicPlaceHolder: 'Search' }, 
  
];

this.cols1 = [
  { field: 'issueCode', header: 'Issue Id', dynamicPlaceHolder: 'Search' },
  { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search' },   
  { field: 'createdByUserName', header: 'Reported By', dynamicPlaceHolder: 'Search' },        
  { field: 'dueDate', header: 'Due Date', dynamicPlaceHolder: 'Search' },
  { field: 'productName', header: 'Product', dynamicPlaceHolder: 'Search' },
  { field: 'statusName', header: 'Status', dynamicPlaceHolder: 'Search' }, 
  { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search' }   
];

this.cols2 = [
  { field: 'issueCode', header: 'Issue Id'},
  { field: 'title', header: 'Title'},    
  { field: 'productName', header: 'Product'},   
  { field: 'statusName', header: 'Status'}      
   
];

this.cols3 = [
  { field: 'fullName', header: 'Name'},
  { field: 'roleName', header: 'Role'},    
  { field: 'departmentName', header: 'Department'}   
   
];

this.historycols = [  
  { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search' },
  { field: 'productNPhase', header: 'Product/Phase', dynamicPlaceHolder: 'Search' },  
  { field: 'isHold', header: 'Project Hold', dynamicPlaceHolder: 'Search' },
  { field: 'dueDate', header: 'Due Date', dynamicPlaceHolder: 'Search' },
  { field: 'actualEffort', header: 'Actual Effort', dynamicPlaceHolder: 'Search' },
  { field: 'statusName', header: 'Status', dynamicPlaceHolder: 'Search' }, 
  { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search' },
  { field: 'modifiedByUserName', header: 'Modified By', dynamicPlaceHolder: 'Search' },
  { field: 'modifiedDate', header: 'Modified On', dynamicPlaceHolder: 'Search' }     
];


this.userId = this.userId.toUpperCase();

  this.getProducts();  
  this.defaultCurrDate = new Date();
  this.selectedIssueDate = new Date(new Date().toString());
  //this.selectedDueDate = new Date(new Date().toString());  
  //this.selectedPriority =  { Id: 1, Name: "Low" };
  //this.selectedStatus =  { Id: 1, Name: "Open" }; 

  //Link Issues
  this.selectedLIToDate=new Date();    
  let dte = new Date();
  dte.setMonth(dte.getMonth() - 1);
  this.selectedLIFromDate= new Date(dte.toString());

  this.selectedLIStatus.push(this.status[0].value,this.status[1].value,this.status[2].value,this.status[3].value,this.status[5].value)
  this.selectedLIStatusChecked.push(1,2,3,4, 7);

  //this.getIssueSearchData();

  //Watchers
  this.getWatcherUsers();  



  }  

  getPriorities() {
    this._facadeService.getPriorities().subscribe(
      (data) => {      
        this.prioritiesData = data.returnObject; 
        //this.setDueDate();    
      },
      (error) => {      
        console.log(error);
      }
    );
  }
  
  setDueDate(){
    var dueDays = this.prioritiesData.find(m=>m.name==this.selectedPriority.name).defaultDueDays; 
    
    let dte = new Date(this.selectedIssueDate);
    dte.setDate(dte.getDate() + dueDays );
    this.selectedDueDate= new Date(dte.toString());
  }

  ValidateStatusForAssignee(){

    if(this.isViewMode == false){
      if(this.userId==this.assignToUserId){
        
        if(this.selectedStatus.code == 5 || this.selectedStatus.code == 7){
            this.selectedStatus = this.TempselectedStatus;
            this.statusError = "Not Authorized to select this status";
        }
        else{
          this.TempselectedStatus = this.selectedStatus;
          this.statusError = "";
        }
      }
    }
  }

  getIssueDetailsById(){
    this.showLoading = true;
    this._facadeService.getIssueDetailsById(this.issueId).subscribe(
      (data) => {
        this.showLoading = false;
        var issueDetails = data.returnObject;
                  
        if(issueDetails!=null){         

          if(this.fromPage == 'r'){
            this.isViewMode = true;
          }
          else
          {
            if((issueDetails.assignToUser != undefined && issueDetails.assignToUser != null && issueDetails.assignToUser.toUpperCase() == this.userId.toUpperCase()) || issueDetails.createdBy.toUpperCase() == this.userId.toUpperCase()){
              this.isViewMode = false;
            }
            else{
              this.isViewMode = true;
            }            
          }
          
          if(this.isViewMode == false){
            if(issueDetails.assignToUser!=null && issueDetails.assignToUser!=undefined && this.userId.toUpperCase() == issueDetails.assignToUser.toUpperCase()){
              this.status= [];              
              this.status = [ 
                {label: 'New', value: {id: 0, name: 'New', code: 1}},
                {label: 'In-Progress', value: {id: 1, name: 'In-Progress', code: 2}},
               {label: 'Resolved', value: {id: 2, name: 'Resolved', code: 3}},
                {label: 'In-Review', value: {id: 3, name: 'In-Review', code: 4}},
            ];
            if(issueDetails.statusName == 'Closed'){
              this.status.push({label: 'Closed', value: {id: 4, name: 'Closed', code: 5}})
            }
            if(issueDetails.statusName == 'Re-Open'){
              this.status.push({label: 'Re-Open', value: {id: 5, name: 'Re-Open', code: 7}})
            }              
            }
          }
          
          this.getIssueSearchData();
          this.issueCode = issueDetails.issueCode;
          this.id = issueDetails.id;
          this.selectedStatus = this.status.find(m=>m.label==issueDetails.statusName).value;
          this.TempselectedStatus = this.status.find(m=>m.label==issueDetails.statusName).value;        

          this.getPriorities();

          this.selectedPriority = this.priority.find(m=>m.label==issueDetails.priority).value;
          this.selectedIssueDate = new Date(issueDetails.issueDate);
          this.selectedProduct = this.productsData.find(m=>m.productID==issueDetails.productId);       
                  
          this.estimatedEffort = issueDetails.estimatedEffort;
          this.actualEffort = issueDetails.actualEffort;
          this.isHold = issueDetails.holdFlag;
          this.title = issueDetails.title;
          this.description = issueDetails.description;

          this.phaseID = issueDetails.phaseId;
          this.assignToUserId = issueDetails.assignToUser;
          this.ViewAddedDocuments = issueDetails.issueAttachments;
          this.notesList = issueDetails.issueNotes;
          this.WatchersList = issueDetails.issueWatchers;
          this.LinksList = issueDetails.issueLinks;

          if(this.WatchersList!=undefined){
            this.WatchersList.forEach((value, key) => {
              this.selectedWatcherChecked.push(value.userGuid);
              this.selectedWatcher.push(value.userGuid);
            });
          }   
          
          if(this.ViewAddedDocuments!=undefined){
            this.ViewAddedDocuments.forEach((value, key) => {              
              value.addedDate = new Date(value.addedDate+'Z');              
            });
          }

          if(this.notesList!=undefined){
            this.notesList.forEach((value, key) => {              
              value.createdDate = new Date(value.createdDate+'Z');              
            });
          }

          if(this.notesList!= undefined){
            this.notesExist = true;
          }
          else{
            this.notesList = [];
          }
        }

        this.getPhaseList();

        if(issueDetails.dueDate!=null){
          this.selectedDueDate = new Date(issueDetails.dueDate);
        }  

        this.getIssueHistory();

        //this.phasesData = issueDetails.phases;

        // if(this.phasesData!=null || this.phasesData!=undefined){
        //   this.selectedPhase = this.phasesData.find(m=>m.phaseID == this.phaseID);         

        //   this.usersData = this.selectedPhase.phaseTeam;
        // }
        

        //this.usersData = issueDetails.assignedUsers;
        //this.mapUserDropDownValue();    

      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  mapPhaseDropDownValue(){
    this.selectedPhase = this.phasesData.find(m=>m.phaseID == this.phaseID);
  }

  mapUserDropDownValue(){
    if(this.assignToUserId!=undefined && this.assignToUserId!=null){
      this.selectedUser = this.usersData.find(m=>m.userID.toUpperCase() == this.assignToUserId.toUpperCase());
    }
  }
  
  filterProducts(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.productsData.length; i++) {
        let doctype = this.productsData[i];
        if (doctype.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(doctype);
        }
    }    
    this.filteredProduct = filtered;
}

  getProducts() {
    this.showLoading = true;
    this._facadeService.getProducts().subscribe(
      (data) => {
        this.showLoading = false;
        this.productsData = data.returnObject;
        //console.log(this.productsData);  
        this.issueId = this.route.snapshot.queryParamMap.get('issueId');
        this.fromPage = this.route.snapshot.queryParamMap.get('frm');

        if(this.issueId!=null && this.issueId!= 'undefined'){
          this.getIssueDetailsById();
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getPhaseList() {

    this.showLoading = true;   
    
    this.phasesData=[];
    this.selectedPhase= [];
    this.filteredPhase= [];
    
    if(this.selectedProduct!=undefined)
    {
    this._facadeService.getPhasesByProductId(this.selectedProduct.productID).subscribe((result: any) => {
        this.showLoading = false;
        this.phasesData = result.returnObject;        

        //this.getUsers();

        if(this.phasesData!=null || this.phasesData!=undefined){
          this.selectedPhase = this.phasesData.find(m=>m.phaseID == this.phaseID);         

          if(this.phaseID==null || this.phaseID==undefined){
            this.selectedPhase = this.phasesData.find(m=>m.activePhase == true);
          }

          this.usersData = this.selectedPhase.phaseTeam;
          this.mapUserDropDownValue();  
        }
        else{
          this.mapUserDropDownValue();  
        }
        

        //this.usersData = issueDetails.assignedUsers;
        //this.mapUserDropDownValue();  

      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
      );
    }
    else
    {
      this.getUsers();
    }
  }

  filterPhases(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.phasesData.length; i++) {
        let doctype = this.phasesData[i];
        if (doctype.phaseName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(doctype);
        }
    }    
    this.filteredPhase = filtered;
    //console.log(this.filteredPhase);
}

getUsers() {
  this.showLoading = true;
  var productGuid = '';
  if(this.selectedProduct!=undefined){
    productGuid = this.selectedProduct.productGuid;
  }
  var phaseName = '';
  if(this.selectedPhase!=undefined){
    phaseName = this.selectedPhase.phaseName;
  }

  this._facadeService.getPhaseUsers(productGuid, phaseName).subscribe(
    (data) => {
      this.showLoading = false;
      this.usersData = data.returnObject;
      //console.log(this.usersData);   
      this.mapUserDropDownValue();         
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

filterUsers(event) {
  let filtered : any[] = [];
  let query = event.query;
  for(let i = 0; i < this.usersData.length; i++) {
      let doctype = this.usersData[i];
      if (doctype.userName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(doctype);
      }
  }    
  this.filteredUser = filtered;
  //console.log(this.filteredPhase);
}

getIssueHistory(){

  this._facadeService.getIssueHistory(this.issueId).subscribe(
    (data) => {
      
      this.HistoryIssuesData = data.returnObject;

    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );  
}

createUpdateIssue(){ 

  this.isValidationFailed = false;
  
  this.titleError = "";
  this.assignedToError = "";
  this.issueDateError = "";
  this.issueDueDateError = "";

  if (this.title == undefined || this.title == "") {
    this.titleError = "Please enter document title.";
    this.isValidationFailed = true;
  }

  // if (this.selectedUser == undefined || this.selectedUser == null) {
  //   this.assignedToError = "Please select Assigned To User.";
  //   this.isValidationFailed = true;
  // }

  if (this.selectedIssueDate == undefined || this.selectedIssueDate == null) {
    this.issueDateError = "Please select Issue Date.";
    this.isValidationFailed = true;
  }

  // if (this.selectedDueDate == undefined || this.selectedDueDate == null) {
  //   this.issueDueDateError = "Please select Issue Due Date.";
  //   this.isValidationFailed = true;
  // }

  if(this.LinkedIssuesData!=undefined){
    this.LinksList = [];
    this.LinkedIssuesData.forEach( (item, index) => {
      this.LinksList.push({LinkIssueId : item.issueGuid});      
    });
  }

  var requestData={
      IssueGuid: this.issueId,
      IssueCode: this.issueCode,
      StatusId: this.selectedStatus.code,
      IssueDate: this.datePipe.transform(this.selectedIssueDate, "dd/MM/yyyy"),
      ProductId: null,//this.selectedProduct.productID,
      ProductName: null,
      productLineID:null,
      PhaseId: null,//this.selectedPhase.phaseID,
      PhaseName: null,
      AssignToUser: null,//this.selectedUser.userGuid,
      Priority: this.selectedPriority.name,
      DueDate: this.datePipe.transform(this.selectedDueDate, "dd/MM/yyyy"),
      EstimatedEffort: (this.estimatedEffort),
      ActualEffort: (this.actualEffort),
      HoldFlag: this.isHold,
      Title: this.title,
      Description: this.description,
      CreatedBy: this.userId,
      IssueAttachments:this.ViewAddedDocuments,
      IssueNotes: this.notesList,
      IssueWatchers:this.WatchersList,
      IssueLinks:this.LinksList
}

if(this.estimatedEffort!=null){
    requestData.EstimatedEffort = (this.estimatedEffort).toString();
} 
if(this.actualEffort!=null){
    requestData.ActualEffort = (this.actualEffort).toString();
}

if(this.selectedProduct!=null){
  requestData.ProductId = this.selectedProduct.productID;
  requestData.ProductName = this.selectedProduct.productName;
  requestData.productLineID = this.selectedProduct.productLineID;
}
if(this.selectedPhase!=null){
  requestData.PhaseId = this.selectedPhase.phaseID;
  requestData.PhaseName = this.selectedPhase.phaseName;
}
if(this.selectedUser!=null){
  requestData.AssignToUser = this.selectedUser.userID;
}

var UtcIssueDate = new Date(this.selectedIssueDate).toUTCString();
requestData.IssueDate = this.datePipe.transform(UtcIssueDate, "MM/dd/yyyy");

if(this.selectedDueDate!=null){
  var UtcDueDate = new Date(this.selectedDueDate).toUTCString();
  requestData.DueDate = this.datePipe.transform(UtcDueDate, "MM/dd/yyyy");
 }

if(this.isValidationFailed ==false){
  this.showLoading = true;
  this._facadeService.UpdateIssue(requestData).subscribe(
    (data) => {
      if(data.returnObject!=null && data.returnObject.issueCode!=null){
        this.issueSuccessMessage = "Issue " + data.returnObject.issueCode + " Created Successfully";
        this.messageDisplay = true;

        this.SendIssueUpdateEmails(data.returnObject.issueCode);

        location.href = this.baseUrl["API_ENDPOINTS"]["ITMUI"]+'myissues';        
      }      
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    } 
  );
}
else{

}


}

SendIssueUpdateEmails(IssueCode){

  this._facadeService.SendIssueUpdateEmails(IssueCode).subscribe(
    (data) => {
    },
    (error) => {
      console.log(error);
    } 
  );
}

AddNewButton() {
  // this.typeError = "";
  // this.codeError = "";
  // this.type = "";
  // this.code = "";
  // this.IstemplateCheckbox = false;
  // this.ShowDocCode = true;
  // this.selectedModule = this.Module[0].value;
  this.addDocsDisplay = true;
  // this.txterror = "";
}

AddNewButton1() {
  this.addLIPDisplay = true;
}

AddNote(){
  if(this.issueNote!=undefined && this.issueNote!= ''){

    let note = {notesAddedByName:this.UserName, createdDate: new Date(), note: this.issueNote, filePath: null, dmsID: null, fileGUID: null };

    if(this.addedNoteDoc!=undefined){
      note.filePath = this.addedNoteDoc.filePath;
      note.dmsID = this.addedNoteDoc.dmsid;
      note.fileGUID = this.addedNoteDoc.fileGuid;
      this.addedNoteDoc = undefined;
    }

  this.notesList.unshift(note,);
  this.notesExist = true;
  this.issueNote = '';  

  this._facadeService.saveIssueNote(note, this.id, this.userId).subscribe(
    (data) => {           
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    } 
  );   

}
}

AddNoteDialog() {
  this.noteDocDisplay = true;
  
}

AddNoteDoc() {
  this.showLoading = true;
  let ok = true;
  if (this.fileInput1 == undefined  || this.fileInput1.files.length == 0) {
    ok = false;
    this.showLoading = false;
      this.DocsError = "Please select atleast one file.";
  }

      if (ok) {        
          this.DocsError = ""; 
          if (this.DocsError == "") {
            const formData = new FormData();
            formData.append("type", this.popuptype);
            formData.append("applicationID", this.applicationID);
            formData.append(
              "documentTitle",
              "ITS"
            );
            formData.append("departmentID", this.UserDepartmentID);
            formData.append("addedBy", this.userId);
            formData.append("addedDate", new Date().toUTCString());
            formData.append("addedByName", this.userId);
            if (this.fileInput1 != undefined) {
              if (this.fileInput1.files.length > 0) {
                this.fileInput1.files.forEach((file) => {
                  formData.append("files", file);
                });
              } else formData.append("files", JSON.stringify([]));
            } else formData.append("files", JSON.stringify([]));
            console.log(formData);
            this._facadeService.AddDocuments(formData)
              .subscribe(
                (data) => {
                  console.log(data);
                  this.apiResponse = data;
                  if (this.apiResponse.returnCode == 0) {
                    this.noteDocDisplay = false;
                    this.showLoading = false;
                    //this.documentsdisplay = true;
                    //this.deletedocument = true;
                    //this.viewdocumentsdisplay = true;
                    if (this.fileInput1 != undefined) {
                      if (this.fileInput1.files.length > 0) {
                        this.fileInput1.clear();
                      }
                    }

                      this.ViewNoteDocuments = this.apiResponse.returnObject;
                      
                      this.addedNoteDoc = this.ViewNoteDocuments[0];
                      

                      if (this.issueNote == undefined || this.issueNote == ''){
                        this.issueNote = "Attachment Added";
                      }

                    this.selectedDoc = [];                    
                  } else {
                    this.showLoading = false;
                    this.DocsError = this.apiResponse.returnMessage;
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        } else {
          this.showLoading = false;
        }       
}

removedocument(fileGuid, dmsid) {
  // this.showLoading = true;
  this.DocsError = "";
  //if (this.userId.toUpperCase() == By.toUpperCase()) {
    this.RemoveID = dmsid;
    // this.confirmation.confirm({
    //   key: "confirm-document",
    //   message: "Are you sure you want to delete a document?",
    //   accept: () => {
    //     this.confirmDeleteDocument = false;
         this._removedoc(fileGuid, dmsid);
    //   },
    // });
    this.confirmDeleteDocument = false;
  // } else {
  //   this.DocsError = "You can't delete other user document.";
  //   this.showLoading = false;
  // }
}
private _removedoc(fileGuid, dmsid) {
  this.showLoading = true;
  this.DeleteModel = {    
    ID: dmsid,
    FileGuid:fileGuid,
    actionBy: this.userId,
    actionDate: new Date(),
    actionByName: "",
  };
  this._facadeService.deleteDocument(this.DeleteModel).subscribe(
    (data) => {
      this.apiResponse = data;
      if (this.apiResponse.returnCode == 0) {
        this.showLoading = false;
        this.ViewAddedDocuments.forEach( (item, index) => {
          if(item.dmsid === this.DeleteModel.ID) this.ViewAddedDocuments.splice(index,1);
        });
       
      } else {
        this.showLoading = false;
        this.DocsError = this.apiResponse.returnMessage;
      }
    },
    (error) => {
      console.log(error);
    }
  );
}

AddDocs() {
  this.showLoading = true;
  let ok = true;

  if(this.UserDepartmentID == null ){
    this.applicationID = sessionStorage.getItem("applicationID");
    this.UserDepartmentID = sessionStorage.getItem("userDepartmentID");
  }

  if (this.fileInput == undefined  || this.fileInput.files.length == 0) {
    ok = false;
    this.showLoading = false;
      this.DocsError = "Please select atleast one file.";
  }

      if (ok) {        
          this.DocsError = ""; 
          if (this.DocsError == "") {
            const formData = new FormData();
            formData.append("type", this.popuptype);
            formData.append("applicationID", this.applicationID);
            formData.append(
              "documentTitle",
              "ITS"
            );
            formData.append("departmentID", this.UserDepartmentID);
            formData.append("addedBy", this.userId);
            formData.append("addedDate", new Date().toUTCString());
            formData.append("addedByName", this.userId);
            if (this.fileInput != undefined) {
              if (this.fileInput.files.length > 0) {
                this.fileInput.files.forEach((file) => {
                  formData.append("files", file);
                });
              } else formData.append("files", JSON.stringify([]));
            } else formData.append("files", JSON.stringify([]));
            console.log(formData);
            this._facadeService.AddDocuments(formData)
              .subscribe(
                (data) => {
                  console.log(data);
                  this.apiResponse = data;
                  if (this.apiResponse.returnCode == 0) {                    
                    this.showLoading = false;

                    this.addDocsDisplay = false;
                    //this.documentsdisplay = true;
                    //this.deletedocument = true;
                    //this.viewdocumentsdisplay = true;
                    if (this.fileInput != undefined) {
                      if (this.fileInput.files.length > 0) {
                        this.fileInput.clear();
                      }
                    }

                    if( this.ViewAddedDocuments!=undefined){
                      this.apiResponse.returnObject.forEach((file) => {
                        this.ViewAddedDocuments.push(file);
                      });                                        
                    }
                    else{
                      this.ViewAddedDocuments = this.apiResponse.returnObject;     
                    }

                    this.selectedDoc = [];                    
                  } else {
                    this.showLoading = false;
                    this.DocsError = this.apiResponse.returnMessage;
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        } else {
          this.showLoading = false;
        }       
}

//Watchers

selectedWatcherChange(selectedWatcherUser){
  console.log(selectedWatcherUser);
  this.selectedWatcherChecked=[];
  selectedWatcherUser.value.forEach((value, key) => {
    this.selectedWatcherChecked.push(value);
  });
} 

getWatcherUsers(){
  this.showLoading = true;
  
  this._facadeService.getAllUsers().subscribe(
    (data) => {
      
      this.tempWatcherUsers = data.returnObject;
      console.log(this.tempWatcherUsers);   
      
      this.tempWatcherUsers.forEach(el => {
        this.WatcherDDLUsers.push({
           label: el.fullName, value: el.userGuid
        });
        //this.selectedUser.push(el.userGuid)
        //this.selectedreportedbyChecked.push(el.userGuid);
     });    

    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );  
}

AddWatcher(){
  this.showLoading = true;
  var data={
    UserGuids:this.selectedWatcherChecked
  }

  this._facadeService.getUserDetailedData(data).subscribe(
    (data) => {
      
      this.WatchersList = data.returnObject;
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );

}

removeWatcher(userGuid){
  this.WatchersList.forEach( (item, index) => {
    if(item.userGuid.toUpperCase() === userGuid.toUpperCase()){ 
      this.WatchersList.splice(index,1);
    }
  });

  this.selectedWatcher.forEach((element,index)=>{
    if(element.toUpperCase()==userGuid.toUpperCase()) this.selectedWatcher.splice(index,1);
 });

}

//Linked Issues
selectedLIStatusChange(selectedLIStatus){
  this.selectedLIStatusChecked=[];
  selectedLIStatus.value.forEach((value, key) => {
    this.selectedLIStatusChecked.push(value.code);
  });
}

filterLIProducts(event) {
  let filtered : any[] = [];
  let query = event.query;
  for(let i = 0; i < this.productsData.length; i++) {
      let doctype = this.productsData[i];
      if (doctype.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(doctype);
      }
  }    
  this.filteredLIProduct = filtered;
}

getIssueSearchData() {
  this.LIissuesData=[];
  var UtcFromDate = new Date(this.selectedLIFromDate).toUTCString();
  var UtcToDate = new Date(this.selectedLIToDate).toUTCString();
  
  var data={
    UserId:this.userId,
    statusIds:this.selectedLIStatusChecked,
    FromDate:this.datePipe.transform(this.selectedLIFromDate, "MM/dd/yyyy",'UTC +4'),
    ToDate:this.datePipe.transform(this.selectedLIToDate, "MM/dd/yyyy",'UTC +4'),
    title:this.LIissueTitle,
    productId:null
  }

  if(this.selectedLIProduct!=null){
    data.productId = this.selectedLIProduct.productID
  }

  //this.showLoading = true;
  this._facadeService.getIssueSearchData(data).subscribe(
    (data) => {
      if(data.returnObject!=null){
        data.returnObject.forEach((value, key) => {
         let convertactiondate = new Date(value.createdAt+'Z') 
          value.createdAt =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
          if(value.issueGuid != this.issueId){
            this.LIissuesData.push(value);
          } 
        });

        this.LoadLinkedIssues();
      }
      //console.log("Issues Search data:"+JSON.stringify(data.returnObject));
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    } 
  );
}  

ClearSearch(){
  this.selectedLIProduct = null;
  this.selectedLIStatus=[];
  this.selectedLIStatusChecked=[];
  this.LIissueTitle = null;
  this.selectedLIStatus.push(this.status[0].value,this.status[1].value,this.status[2].value,this.status[3].value,this.status[5].value)
  this.selectedLIStatusChecked.push(1,2,3,4, 7);
  this.getIssueSearchData();
}

LinkIssue(rowdata){ 
  if(this.LinkedIssuesData != undefined){
      var issue = this.LinkedIssuesData.find(m=>m.issueGuid==rowdata.issueGuid);

      if(issue == undefined ){
        this.LinkedIssuesData.push(this.LIissuesData.find(m=>m.issueGuid==rowdata.issueGuid));
      }
  }
  else{
    this.LinkedIssuesData = [];
    this.LinkedIssuesData.push(this.LIissuesData.find(m=>m.issueGuid==rowdata.issueGuid));
  }
}

removeLinkedIssue(rowdata){
  this.LinkedIssuesData.forEach( (item, index) => {
    if(item.issueGuid === rowdata.issueGuid) this.LinkedIssuesData.splice(index,1);
  });
}

LoadLinkedIssues(){
  this.LinkedIssuesData = [];
  if(this.LinksList!=undefined){
    this.LinksList.forEach((value, key) => {
      this.LinkedIssuesData.push(this.LIissuesData.find(m=>m.issueGuid==value.linkIssueId));
    });
  }
}

onBasicUploadAuto(event){  

  this.showLoading = true;
  let ok = true;
  
  if (ok) {        
    this.DocsError = ""; 
    if (this.DocsError == "") {
      const formData = new FormData();
      formData.append("type", this.popuptype);
      formData.append("applicationID", this.applicationID);
      formData.append(
        "documentTitle",
        "ITS"
      );
      formData.append("departmentID", this.UserDepartmentID);
      formData.append("addedBy", this.userId);
      formData.append("addedDate", new Date().toUTCString());
      formData.append("addedByName", this.userId);
      if (event != undefined) {
        if (event.files.length > 0) {
          event.files.forEach((file) => {
            formData.append("files", file);
          });
        } else formData.append("files", JSON.stringify([]));
      } else formData.append("files", JSON.stringify([]));
      console.log(formData);
      this._facadeService.AddDocuments(formData)
        .subscribe(
          (data) => {
            console.log(data);
            this.apiResponse = data;
            if (this.apiResponse.returnCode == 0) {
              this.addDocsDisplay = false;
              this.showLoading = false;
              if (this.fubauto != undefined) {
                if (this.fubauto.files.length > 0) {
                  this.fubauto.clear();
                }
              }
              
              let ViewNoteDocuments = this.apiResponse.returnObject;                      
              this.addedNoteDoc = ViewNoteDocuments[0];
              if (this.issueNote == undefined || this.issueNote == ''){
                this.issueNote = " File: " + this.addedNoteDoc.fileName + " Added";
              }
  
            this.selectedDoc = [];  
            
            let note = {notesAddedByName:this.UserName, createdDate: new Date(), note: this.issueNote, filePath: null, dmsID: null, fileGUID: null };
  
                if(this.addedNoteDoc!=undefined){
                   note.filePath = this.addedNoteDoc.filePath;
                   note.dmsID = this.addedNoteDoc.dmsid;
                   note.fileGUID = this.addedNoteDoc.fileGuid;
                  this.addedNoteDoc = undefined;
                }
  
                this.notesList.unshift(note,);
                this.notesExist = true;
                this.issueNote = '';

                this._facadeService.saveIssueNote(note, this.id, this.userId).subscribe(
                  (data) => {           
                    this.showLoading = false;
                  },
                  (error) => {
                    this.showLoading = false;
                    console.log(error);
                  } 
                );

                this.showLoading = false;
                               
            } else {
              this.showLoading = false;
              this.DocsError = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
    }
  }
  
  }

}