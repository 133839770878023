import { Injectable } from "@angular/core";
import { HttputilityService } from "./httputility.service";
@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private _httputilityService: HttputilityService) {} 
  

  getReportedByUsers() {
    return this._httputilityService.Get('GetReportedByUsers');
  }

  getReportSearchIssueData(data) {
    return this._httputilityService.Post('GetReportSearchIssueData',data);
  }

  getPhasesByProductId(productId){
    return this._httputilityService.GetP('Phase/GetPhasesbyProductID?ProductID='+productId);
  }

  getPhaseUsers(productGuid, phaseName){
    //return this._httputilityService.GetP('Product/GetPhaseUsers?ProductGuid='+productGuid + '&PhaseName='+ phaseName);
    return this._httputilityService.Get("GetAssignedToUsers?ProductGuid="+productGuid + "&PhaseName="+ phaseName);
  }

  getAllUsers(){    
    return this._httputilityService.Get("GetAllUsers");
  }

  getUserDetailedData(userIds){    
    return this._httputilityService.Post("GetUsersDetailedData", userIds);
  }

  createIssue(requestData) {
    return this._httputilityService.Post('CreateIssue',requestData);
  }

  SendIssueCreationEmails(IssueCode){
    return this._httputilityService.Get('SendIssueCreationEmails?IssueCode=' + IssueCode);
  }

  updateIssue(requestData) {
    return this._httputilityService.Post('UpdateIssue',requestData);
  }

  SendIssueUpdateEmails(IssueCode){
    return this._httputilityService.Get('SendIssueUpdateEmails?IssueCode=' + IssueCode);
  }

  getIssueCodes(){
    return this._httputilityService.Get('GetIssueCodes');
  }

  getIssueDetailsById(issueId){
    return this._httputilityService.Get("GetIssueDetailsById?IssueGuid=" + issueId);
  }

  getIssueHistory(issueId){
    return this._httputilityService.Get("GetIssueHistory?issueId=" + issueId);
  }

  DataSourceAsQuery(datasource){
    return this._httputilityService.GetP('CustomFields/Getdata?Query='+ datasource);
  }

  deleteDocument(model) {
    return this._httputilityService.Post("DeleteDocument_ITS", model);
  }

  saveIssueNote(note, issueId, userId){
    return this._httputilityService.Post('AddIssueNote?issueId=' + issueId + '&userId=' + userId, note);
  }

}
