<div class="p-grid p-fluid">
  <div class="p-col-12">

    <div class="card">
      <h5>Issue Details</h5>
      <hr>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2">
              <label for="Title">Issue ID</label>
              <input id="Title" type="text" pInputText placeholder="Issue ID" [(ngModel)]="issueCode"
                [disabled]="true" />
              <!-- <small class="p-invalid">Issue Error</small> -->
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="Department">Status<span style="color: red">*</span></label>
              <p-dropdown [options]="status" [(ngModel)]="selectedStatus" [readonly]="isViewMode">
              </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="city"> Date<span style="color: red">*</span></label>
              <p-calendar [showIcon]="true" inputId="icon" [maxDate]="defaultCurrDate" placeholder="Select Date"
                [(ngModel)]="selectedIssueDate" (onSelect)="setDueDate()" [disabled]="isViewMode"></p-calendar>
              <small class="p-invalid">{{issueDateError}}</small>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="state">Product</label>
              <p-autoComplete [(ngModel)]="selectedProduct" [suggestions]="filteredProduct"
                (completeMethod)="filterProducts($event)" (onSelect)="getPhaseList()" field="productName"
                [dropdown]="true" [disabled]="isViewMode">
                <ng-template>
                  <div>
                    <div>{{ filteredProduct.productName }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="Department">Phase</label>
              <p-autoComplete [(ngModel)]="selectedPhase" [suggestions]="filteredPhase"
                (completeMethod)="filterPhases($event)" field="phaseName" (onSelect)="getUsers()" [dropdown]="true"
                [disabled]="isViewMode">
              </p-autoComplete>
              <!-- <small class="p-invalid">Phase Error</small> -->
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="state">Assign To User</label>
              <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="filteredUser"
                (completeMethod)="filterUsers($event)" field="userName" [dropdown]="true" [disabled]="isViewMode">
              </p-autoComplete>
              <small class="p-invalid">{{assignedToError}}</small>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="Priority">Priority</label>
              <p-dropdown [options]="priority" [(ngModel)]="selectedPriority" (onChange)="setDueDate()"
                [readonly]="isViewMode">
              </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="city"> Due Date</label>
              <p-calendar [showIcon]="true" [minDate]="selectedIssueDate" inputId="icon" placeholder="Due Date"
                [(ngModel)]="selectedDueDate" [disabled]="isViewMode"></p-calendar>
              <small class="p-invalid">{{issueDueDateError}}</small>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="Title">Estimated Effort (Hours)</label>
              <input id="Title" type="text" pInputText placeholder="Enter Duration" [(ngModel)]="estimatedEffort"
                [disabled]="isViewMode" />
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label for="Title">Actual Effort (Hours)</label>
              <input id="Title" type="text" pInputText placeholder="Enter Duration" [(ngModel)]="actualEffort"
                [disabled]="isViewMode" />
            </div>
            <div class="p-field-checkbox p-col-12 p-md-2" style="margin-top: 15px;">
              <p-checkbox binary="true" inputId="binary" [(ngModel)]="isHold" [disabled]="isViewMode"></p-checkbox>
              <label>Project Hold</label>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="title">Title <span style="color: red">*</span></label>
              <input type="text" pInputText placeholder="Title" required="true"
                requiredMessage="Title must not be empty" [(ngModel)]="title" [disabled]="isViewMode" />
              <small class="p-invalid">{{titleError}}</small>
            </div>
            <!-- <div class="p-field p-col-12" style="margin-top: 10px">
              <label for="Description">Description</label>         
              <p-editor [(ngModel)]="description" [style]="{'height':'160px'}" [disabled]="true"></p-editor>              
              <br>
            </div> -->
          </div>
    </div>

              <div class="card">
                <p-tabView [activeIndex]="index">
                  <p-tabPanel header="Description" leftIcon="pi pi-align-left">
                    <br>
                    <p-editor [(ngModel)]="description" [style]="{'height':'160px'}" [readonly]="true"></p-editor>
                  </p-tabPanel>
                  <p-tabPanel header="Attachments" leftIcon="pi pi-paperclip">
                    <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
                      <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
                        (click)="AddNewButton()" class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2"
                        style="width:auto;float: right;" [disabled]="isViewMode"></button>
                    </span>
                    <span class="p-input-icon-left" style="float: right">
                      <p-dialog header="Add Documents" [(visible)]="addDocsDisplay" modal="modal" showEffect="fade"
                        [style]="{ width: '60%' }">
                        <hr /><br>
                        <div class="p-grid">
                          <div class="p-col-12 p-md-12 p-xl-12">
                            <form>
                              <p-fileUpload #fileInput name="file" multiple="multiple"
                                accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt"
                                maxFileSize="20971520" [showUploadButton]="false" [showCancelButton]="false"
                                fileLimit="25" auto="true">
                                <ng-template pTemplate="content">
                                  <ul *ngIf="fileInput.length">
                                    <li *ngFor="let file of fileInput">{{file.name}} - {{file.size}} bytes</li>
                                  </ul>
                                </ng-template>
                              </p-fileUpload>
                            </form>
                          </div>
                          <div class="p-col-12" style="float: right;">
                            <button pButton type="button" (click)="AddDocs()" label="Add" pTooltip="Add Docs"
                              tooltipPosition="right" icon="pi pi-plus" style="width:25px;height:25px;"
                              class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                              style="width: 100px;height:25px; float: right;margin-left:120px;  margin-top: 20px;"></button>
                          </div>
                        </div>
                      </p-dialog>
                    </span>

                    <div class="p-col-12">
                      <div class="card-header"
                        style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                        <h5 style="color:#495057; padding:15px 0 0 15px;">Attachments</h5>
                      </div>
                      <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">

                        <p-table #dtViewDocuments [value]="ViewAddedDocuments" [columns]="cols" sortMode="multiple"
                          class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                          selectionMode="multiple" dataKey="id">
                          <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                              [ngClass]="{'documentName':i==0, 'uploadedby': i == 1, 'uploadedon':i==2}">
                                {{col.header}}

                              </th>
                              <th style="width:80px;">Action</th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData>
                            <tr class="p-selectable-row">
                              <td style="text-align: center"><span><a href="{{ DMSAPIUURL }}{{rowData.filePath}}">
                                    {{rowData.fileName}} </a></span></td>
                              <td style="text-align: center"><span>{{rowData.addedByName}}</span></td>
                              <td style="text-align: center"><span>{{rowData.addedDate | date:'MM/dd/yyyy HH:mm
                                  a'}}</span>
                              </td>
                              <td style="text-align: center;width: 50px;">
                                <button pButton type="button" [disabled]="ViewOnly" DMSAPIUURL
                                  (click)="removedocument(rowData.fileGuid,rowData.dmsid)" label="" icon="pi pi-minus"
                                  class="p-button-primary p-button-info p-mr-2 p-mb-2"
                                  style="width: 25px; height: 25px;" [disabled]="isViewMode"></button>
                                <!-- <p-confirmDialog key="confirm-document" header="Delete Document" acceptLabel="Yes" rejectLabel="No" [(visible)]="confirmDeleteDocument"></p-confirmDialog> -->
                              </td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="4" style="text-align: left;">No records found.</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </p-tabPanel>

                  <p-tabPanel header="Linked Issues" leftIcon="pi pi-upload">
                    <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
                      <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Link Issue"
                        (click)="AddNewButton1()" class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2"
                        style="width:auto;float: right;" [disabled]="isViewMode"></button>
                    </span>
                    <span class="p-input-icon-left" style="float: right">
                      <p-dialog header="Add Linked Issues" [(visible)]="addLIPDisplay" modal="modal" showEffect="fade"
                        [style]="{ width: '90%', height: '70%' }">
                        <hr /><br>
                        <div class="p-grid p-fluid p-formgrid">                           
                          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                            <label for="city"> From</label>
                            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedLIFromDate"></p-calendar>
                          </div>
                          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                            <label for="city"> To</label>
                            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedLIToDate"></p-calendar>
                          </div>
                          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                            <label for="Department">Status</label>
                            <p-multiSelect [options]="status" [(ngModel)]="selectedLIStatus"
                              (onChange)="selectedLIStatusChange($event)" class="multiselect-custom"
                              defaultLabel="Select Status" autoWidth="false">
                              <ng-template let-value pTemplate="selectedItems">
                                <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                                  <div>option.name</div>
                                </div>
                                <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
                                  Select Status
                                </div>
                              </ng-template>
                            </p-multiSelect>
                          </div>
                          <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12">
                            <label for="title">Issue Title</label>
                            <input type="text" pInputText placeholder="Issue Title" [(ngModel)]="LIissueTitle" />
                          </div>
                          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                            <label for="Product">Product</label>
                            <p-autoComplete [(ngModel)]="selectedLIProduct" [suggestions]="filteredLIProduct"
                              (completeMethod)="filterLIProducts($event)" field="productName" [dropdown]="true">
                              <ng-template>
                                <div>
                                  <div>{{ filteredLIProduct.productName }}</div>
                                </div>
                              </ng-template>
                            </p-autoComplete>
                          </div>                       
                          <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
                            <a (click)="getIssueSearchData()">
                              <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search"
                                class="p-button-raised p-button-primary"
                                style="width:100%; margin-top: 25px;float: right;"></button>
                            </a>
                          </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                          <div class="p-field p-col-12 p-lg-11 p-md-12 p-sm-12"></div>
                            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
                            <button pButton type="button" (click)="ClearSearch()" label="Clear" pTooltip="Clear Search"
                              tooltipPosition="right" class="p-button-primary p-button-raised p-button-info"
                              style="width: 100%;margin-top: 25px;"></button>
                          </div>
                        </div>

                        <div class="p-fluid p-formgrid p-grid">
                          <div class="p-col-12 p-md-12 p-xl-12" style="overflow-x: auto;">
                            <p-table #dt [value]="LIissuesData" [columns]="cols2" sortMode="multiple"
                              class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                              selectionMode="multiple" dataKey="id">
                              <ng-template pTemplate="header" let-columns class="invoice-items">
                                <tr>
                                  <!-- <th style="width: 3rem">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th> -->
                                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                  [ngClass]="{'issueCode': i == 0,'title': i == 1, 'productName': i == 2, 'statusName':i==3}">
                                    {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                                  </th>
                                  <th style="width:80px;">Actions</th>
                                </tr>
                                <!-- <tr>
                              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                  (input)="dt.filter($event.target.value, col.field, 'contains')"
                                  style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                              </th>
                              <th></th>
                            </tr> -->
                              </ng-template>
                              <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr [pSelectableRow]="rowData">
                                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                    <!-- <td style="text-align:left; padding-left:15px;">
                                  <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                                </td>  -->
                                    <td *ngIf="i == 0" style="text-align: center;">
                                      {{ rowData[col.field] }}
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                      {{ rowData[col.field] }}
                                    </td>
                                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                      {{rowData[col.field]}}
                                    </td>
                                    <td *ngIf="i == 3" style="text-align: center;">
                                      {{rowData[col.field]}}
                                    </td>
                                  </ng-container>
                                  <td style="text-align: center;">
                                    <button pButton type="button" (click)="LinkIssue(rowData)" icon="pi pi-plus"
                                      pTooltip="Link Issue" class="p-button-raised p-button-primary"
                                      style="width:25px; height:25px;"></button>
                                  </td>
                                </tr>
                              </ng-template>
                              <ng-template pTemplate="emptymessage">
                                <tr>
                                  <td colspan="5" style="text-align: left;">No records found.</td>
                                </tr>
                              </ng-template>
                            </p-table>
                          </div>
                        </div>
                      </p-dialog>
                    </span>

                    <div class="p-col-12">
                      <div class="card-header">
                        <h5 style="color:#fff;">Linked Issues</h5>
                      </div>
                      <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
                        <p-table #dt [value]="LinkedIssuesData" [columns]="cols1" sortMode="multiple"
                          class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                          selectionMode="multiple" dataKey="id">
                          <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                              [ngClass]="{'issueCode': i == 0,'title': i == 1, 'createdByUserName': i == 2, 'dueDate':i==3, 'productName':i==4, 
                              'statusName':i==5, 'priority':i==6 }">
                                {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                              </th>
                              <th style="width:80px;">Actions</th>
                            </tr>
                            <!-- <tr>
                          <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                            <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                              (input)="dt.filter($event.target.value, col.field, 'contains')"
                              style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                          </th>
                          <th></th>
                        </tr> -->
                          </ng-template>

                          <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                                  {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                  {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                  {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                                  {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                </td>
                                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;">
                                  {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 5" style="text-align: center;">
                                  {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 6" style="text-align: center;">
                                  {{rowData[col.field]}}
                                </td>
                              </ng-container>
                              <td style="text-align: center;">
                                <button pButton type="button" (click)="removeLinkedIssue(rowData)" icon="pi pi-minus"
                                  pTooltip="Remove Link" class="p-button-raised p-button-primary"
                                  style="width:25px; height:25px;" [disabled]="isViewMode"></button>
                              </td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="8" style="text-align: left;">No records found.</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </p-tabPanel>

                  <p-tabPanel header="Watchers" leftIcon="pi pi-eye">
                    <!-- <div class="card-header">
                    <h5 style="color:#fff;">Watchers</h5>
                  </div> -->
                    <div class="p-fluid p-formgrid p-grid">
                      <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
                      <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12">
                        <span class="p-input-icon-left" style="float:right;margin-top:8px;margin-right: 15px;">
                          <p-multiSelect [options]="WatcherDDLUsers" [(ngModel)]="selectedWatcher"
                            (onChange)="selectedWatcherChange($event)" autoWidth="false"
                            class="multiselect-custom" defaultLabel="Select Watcher">
                            <ng-template let-value pTemplate="selectedItems">
                              <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                                <div>option.fullName</div>
                              </div>
                              <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
                                Select
                              </div>
                            </ng-template>
                          </p-multiSelect>
                        </span>
                      </div>
                      <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
                        <span class="p-input-icon-left" style="float:right;margin-top:8px;">
                          <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
                            class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2"
                            style="width:auto;float: right;" (click)="AddWatcher()" [disabled]="isViewMode"></button>
                        </span>
                      </div>
                    </div>
                    <div class="p-col-12">

                      <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">

                        <p-table #dt [value]="WatchersList" [columns]="cols3" sortMode="multiple" class="table-align"
                          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id">
                          <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                              [ngClass]="{'fullName': i == 0, 'roleName': i == 1, 'departmentName': i == 2}">
                                {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                              </th>
                              <th style="width:80px;">Actions</th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                <td *ngIf="i == 0" style="text-align: center;padding-left:15px;">
                                  {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: center;padding-left:15px;">
                                  {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 2" style="text-align: center;padding-left:15px;">
                                  {{rowData[col.field]}}
                                </td>
                              </ng-container>
                              <td style="text-align: center;">
                                <button pButton type="button" [disabled]="ViewOnly"
                                  (click)="removeWatcher(rowData.userGuid)" label="" icon="pi pi-minus"
                                  class="p-button-primary p-button-info p-mr-2 p-mb-2"
                                  style="width: 25px; height: 25px;" [disabled]="isViewMode"></button>
                              </td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="4" style="text-align: left;">No records found.</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </p-tabPanel>
                  <p-tabPanel header="Issue History" leftIcon="pi pi-upload">

                    <div class="p-col-12">
                      <div class="card-header">
                        <h5 style="color:#fff;">Issue History</h5>
                      </div>
                      <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
                        <p-table #dt [value]="HistoryIssuesData" [columns]="historycols" sortMode="multiple"
                          class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                          selectionMode="multiple" dataKey="id">
                          <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                              [ngClass]="{'title': i == 0,'productNPhase': i == 1, 'isHold': i == 2, 'dueDate':i==3, 
                              'actualEffort':i==4, 'statusName':i==5, 'priority':i==6 , 'modifiedByUserName':i==7 , 'modifiedDate':i==8 }">
                                {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                              </th>
                            </tr>
                            <!-- <tr>
                          <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                            <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                              (input)="dt.filter($event.target.value, col.field, 'contains')"
                              style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                          </th>
                          <th></th>
                        </tr> -->
                          </ng-template>

                          <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                                  {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                  {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                  {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                                  {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                </td>
                                <td *ngIf="i == 4" style="text-align: center;">
                                  {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 5" style="text-align: center;">
                                  {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 6" style="text-align: center;">
                                  {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 7" style="text-align: left;padding-left:15px;">
                                  {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 8" style="text-align: left;padding-left:15px;">
                                  {{rowData[col.field] | date:'MM/dd/yyyy'}}
                                </td>
                              </ng-container>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="8" style="text-align: left;">No records found.</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </p-tabPanel>
                </p-tabView>

            <div class="p-col-12" style="margin-bottom:10px; display: none;">
              <div class="p-fluid p-formgrid p-grid" style="border:1px solid #eee;">
                <div class="p-field p-col-12 p-md-4" style="margin-top: 10px">

                  <label for="Title">Attachments:</label><br>
                  <div style="height: 200px; border: 1px solid #eee;overflow-y: scroll;">
                    <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br><br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                  </div>

                  <input type="file" #fileInput value="" style="display: none" />
                  <!-- <small class="p-invalid">Error Message</small> -->
                  <label pButton for="upload" pRipple type="button" icon="pi pi-paperclip" iconPos="right"
                    label="&nbsp;&nbsp;Choose file" class="p-button-raised p-button-info p-mr-2 p-mb-2"></label>
                </div>
                <div class=" p-field p-col-12 p-md-4" style="margin-top: 10px;  display: none;">

                  <label for="Title">Link To Issues:</label><br>
                  <div style="height: 200px; border: 1px solid #eee;overflow-y: scroll;">
                    <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br><br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                  </div>

                  <input type="file" #fileInput value="" style="display: none" />
                  <!-- <small class="p-invalid">Error Message</small> -->
                  <label pButton for="upload" pRipple type="button" icon="pi pi-globe" iconPos="right"
                    label="&nbsp;&nbsp;Browse" class="p-button-raised p-button-info p-mr-2 p-mb-2"></label>
                </div>
                <div class="p-field p-col-12 p-md-4" style="margin-top: 10px; display: none;">
                  <label for="Title">Watchers:</label><br>
                  <div style="height: 200px; border: 1px solid #eee;overflow-y: scroll;">
                    <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br><br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                    <br> <br>
                    <span style="margin-top: 15px;height:40px;">
                      <span style="width: 80%;padding-left: 15px;">
                        Rf4305da267faaed218cd9b055374b387
                      </span>
                      <span>
                        <button pButton pRipple type="button" icon="pi pi-times"
                          class="p-button-raised p-button-info p-mr-2 p-mb-2"
                          style="float: right; height:25px;width: 25px;"></button></span>
                    </span>
                  </div>

                  <input type="file" #fileInput value="" style="display: none" />
                  <!-- <small class="p-invalid">Error Message</small> -->
                  <label pButton for="add" pRipple type="button" icon="pi pi-plus" iconPos="right"
                    label="&nbsp;&nbsp;Add" class="p-button-raised p-button-info p-mr-2 p-mb-2"></label>
                </div>

              </div>
            </div>

            <div class="p-field p-col-12">
              <div class="p-grid">
                <div class="p-col-12" style="float:right;">
                  <hr>
                  <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                    class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="createUpdateIssue()"
                    style="width:auto; float:right; "></button>
                </div>
                <div class="card-header"
                  style=" border-radius: 5px; width:98.3%;height:40px;margin-left: 10px;margin-bottom:2px;">
                  <h5 style="color:#495057; padding:15px 0 0 15px;">Notes:</h5>
                </div>

                <div class="p-field p-col-12">
                  <div class="card" style="border:1px solid #eee;">
                    <div class="widget-chat">
                      <ul *ngFor="let note of notesList">
                        <li class="message-from">
                          <div><span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                              note.notesAddedByName }}</span>
                            <span style="font-size: 14px; font-weight:bold; padding-right: 10px;">{{
                              note.createdDate | date:'MMM dd, yyyy hh:mm a'}}
                            </span>
                            <span style="font-size: 14px;padding-right: 10px;">{{ note.note }}</span>
                          </div>
                          <div *ngIf="note.filePath" style="background-color: #fff;border:none"><a
                              href="{{ DMSAPIUURL }}{{note.filePath}}" class="p-button-raised p-button-info p-mr-2 p-mb-2">
                              <span style="float:right; height:30px; width:30px;background-color: #0288D1;color: #ffffff;
                              border-radius: 5px;padding-left: 8px;padding-top:5px;margin-top:-5px;">
                                <i class="pi pi-download"></i></span></a></div>
                        </li>
                      </ul>
      
                      <div class="message-input p-field p-col-12 ">
                        <input type="text" placeholder="Write a note here" class="p-inputtext" [(ngModel)]="issueNote"
                          style="background-color: #eee;border:1px solid #bbb;" />
      
      
                        <div class="card-header">
      
                          <p-fileUpload #fubauto mode="basic" customUpload="true"
                            accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt" maxFileSize="20971520"
                            (uploadHandler)="onBasicUploadAuto($event)" auto="true" chooseLabel=" File"
                            pTooltip="Attach file"></p-fileUpload>
                          <span class="p-input-icon-left" style="float:right;margin-top:8px; margin-left:10px; width:80px;">
                            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
                              class="p-button-raised p-button-info p-mr-2 p-mb-2" (click)="AddNote()">
                            </button>
      
                          </span>
                        </div>
                      </div>
      
                      <!-- </div>
                        </div>                 
                      </div> -->
                      <span class="p-input-icon-left" style="float: right">
                        <p-dialog header="Add Note Attachment" [(visible)]="noteDocDisplay" modal="modal" showEffect="fade"
                          [style]="{ width: '60%' }">
                          <hr /><br>
                          <div class="p-grid">
      
                            <div class="p-col-12 p-md-12 p-xl-12">
                              <form>
                                <p-fileUpload #fileInput1 name="file"
                                  accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt"
                                  maxFileSize="20971520" [showUploadButton]="false" [showCancelButton]="false" fileLimit="1"
                                  auto="true">
                                  <ng-template pTemplate="content">
                                    <ul *ngIf="fileInput1.length">
                                      <li *ngFor="let file of fileInput1">{{file.name}} - {{file.size}} bytes</li>
                                    </ul>
                                  </ng-template>
                                </p-fileUpload>
                              </form>
                            </div>
                            <div class="p-col-12" style="float: right;">
                              <button pButton type="button" (click)="AddNoteDoc()" label="Add" pTooltip="Add Docs"
                                tooltipPosition="right" icon="pi pi-plus" style="width:25px;height:25px;"
                                class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
                                style="width: 100px;height:25px; float: right;margin-left:120px;"></button>
                            </div>
                          </div>
                        </p-dialog>
                      </span>

                <!-- <div class="p-field p-col-12 p-md-10" style="margin-top:10px;">
                  <textarea [(ngModel)]="issueNote" id="issueNote" type="text" rows="2" [maxLength]="300" placeholder="Enter your note here..." pInputTextarea>

                  </textarea>
                </div>
                <div class="p-field p-col-12 p-md-2">
                  <span class="p-input-icon-left" style="margin-top:15px;">
                    <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add Note"
                      class="p-button-primary p-button-raised p-button-info p-mr-2 p-mb-2" (click)="AddNote()"
                      style="width: 80%;float: right;">
                    </button>
                  </span>
                </div> -->
                <!-- <br><br> -->

              </div>
            </div>
            <hr>
            <div class="p-field p-col-12" style="display: none;">
              <h4>Discussions</h4>
              <div class="card" style="border:1px solid #eee;">
                <div class="widget-chat">
                  <ul style="min-height:100px;">
                    <li class="message-from">
                      <img src="assets/demo/images/avatar/ionibowcher.png" alt="diamond-layout" />
                      <div>Retro occupy organic, stumptown shabby chic pour-over roof party DIY normcore.</div>
                    </li>
                    <!-- <li class="message-own">
                                <img src="assets/demo/images/avatar/onyamalimba.png" alt="diamond-layout" />
                                <div>Actually artisan organic occupy, Wes Anderson ugh whatever pour-over gastropub selvage.</div>
                            </li> -->
                    <li class="message-from">
                      <img src="assets/demo/images/avatar/ionibowcher.png" alt="diamond-layout" />
                      <div>Chillwave craft beer tote bag stumptown quinoa hashtag.</div>
                    </li>
                    <!-- <li class="message-own">
                                <img src="assets/demo/images/avatar/onyamalimba.png" alt="diamond-layout" />
                                <div>Dreamcatcher locavore iPhone chillwave, occupy trust fund slow-carb distillery art party narwhal.</div>
                            </li>   -->
                  </ul>
                  <div class="new-message">
                    <div class="message-attachment">
                      <i class="pi pi-paperclip"></i>
                    </div>
                    <div class="message-input">
                      <input type="text" placeholder="Write a message" class="p-inputtext" />
                      <!-- <button
                                pButton
                                pRipple
                                type="button"
                                icon="pi pi-reply"
                                iconPos="left"
                                label="Enter"
                                class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                              style="width:100px;"
                              ></button> -->
                    </div>

                  </div>
                </div>
              </div>
              <div class="p-grid">
                <div class="p-field p-col-12 p-md-11">
                </div>
                <div class="p-field p-col-12 p-md-1">
                  <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
                    class="p-button-raised p-button-primary p-mr-2 p-mb-2"></button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>

<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>