import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {LoginComponent} from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MyissuesComponent } from './components/myissues/myissues.component';
import { ReportissueComponent } from './components/reportissue/reportissue.component';
import { AssignedtomeComponent } from './components/assignedtome/assignedtome.component';
import { IssuesearchComponent } from './components/issuesearch/issuesearch.component';
import { AdminComponent } from './components/admin/admin.component';
import { ReportComponent } from './report/report.component';
import { EditIssueComponent } from './components/editissue/editissue.component';
import { EntrypointComponent } from "./components/entrypoint/entrypoint.component";
import {AuthGuard} from './guard/auth.guard';
import { HelpguideComponent } from './components/helpguide/helpguide.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: "", component:LoginComponent},
              { path: "", 
              component: AppMainComponent,
                
                children: [
                    { path: 'entrypoint', component:EntrypointComponent},
                    { path: "dashboard", component: DashboardComponent,canActivate:[AuthGuard] },
                    { path: "myissues", component: MyissuesComponent ,canActivate:[AuthGuard]},
                    { path: "reportissue", component: ReportissueComponent,canActivate:[AuthGuard] },
                    { path: "assignedtome", component: AssignedtomeComponent ,canActivate:[AuthGuard]},
                    { path: "issuesearch", component: IssuesearchComponent ,canActivate:[AuthGuard]},
                    { path: "admin", component: AdminComponent,canActivate:[AuthGuard] },
                    { path: "report", component: ReportComponent ,canActivate:[AuthGuard]},
                    { path: "editissue", component: EditIssueComponent ,canActivate:[AuthGuard]},
                    { path: "helpguide", component: HelpguideComponent ,canActivate:[AuthGuard]},
                   
                ]
            },
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
