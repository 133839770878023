import {Component, Inject, OnDestroy} from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { BASE_API_URL_TOKEN } from './injectors';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from './facade/facade.service';
import { API_ENDPOINTS } from './app.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnDestroy{
    subscription: Subscription;
    firstName = sessionStorage.getItem('firstName');
    profileDisplayExist = sessionStorage.getItem('profileDisplayExist')=="true"?true:false;
    profileDisplayPic = sessionStorage.getItem('profileDisplayPic');
    applications = JSON.parse(sessionStorage.getItem('applications'));
    userGuid = sessionStorage.getItem('userGuid');
    items: MenuItem[];
    departments = [];
    TopMenuDisplay = false;
    userId = parseInt(sessionStorage.getItem('userId'));
    showLoading:boolean;
    TopMenuItemsList:any[]=[];
    HeaderText:boolean=true;
    constructor(
      public breadcrumbService: BreadcrumbService,
      public app: AppMainComponent,
      private route: ActivatedRoute,
      private _facadeService: FacadeService,
      private router: Router,
      @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
    ) {
      var m = window.innerWidth;
      if(m<= 500)
        this.HeaderText = false;
      this.subscription = breadcrumbService.itemsHandler.subscribe((response) => {
        this.items = response;
      });
      var bearer_token=this.route.snapshot.queryParamMap.get('bt');
    if(bearer_token!=null&&bearer_token!=""&&bearer_token!=undefined){
      sessionStorage.bt= bearer_token;
    }
      var guid = this.route.snapshot.queryParamMap.get('userguid');
      var userId = this.route.snapshot.queryParamMap.get('userId');
      
      if(guid!=null){
        this.userGuid = guid;
      }

      if(userId!=null){
        this.userId = parseInt(userId);
      }       

      if(this.userGuid!='' && this.userGuid!=undefined && this.userGuid!=null){
        this.getUserDetails();        
      }
    }
  
      getUserDetails(){
        this.showLoading = true;
       this._facadeService.GetUserDetails(this.userGuid)
      .subscribe(
         (data) => {
          this.showLoading = false;
        var userDetails = data.returnObject;
        sessionStorage.userId = userDetails.userId;
        sessionStorage.userGuid =  this.userGuid;
        this.applications=userDetails.userApplications;
        sessionStorage.un= userDetails.username;
        sessionStorage.pwd= userDetails.password;
        sessionStorage.defaultApplication = userDetails.defaultApplication;
        sessionStorage.defaultIdentifierApp = userDetails.defaultIdentifierApp;
        sessionStorage.applications = JSON.stringify(userDetails.userApplications);
        this.applications.forEach((value, key) => {
          if(value.name == "Issue Tracker")
              sessionStorage.applicationID = value.id;
        });
        sessionStorage.roles = JSON.stringify(userDetails.userRoles);
        sessionStorage.firstName= userDetails.firstName;
        this.firstName= userDetails.firstName;
        this.departments = userDetails.userDepartments;
        this.departments= this.departments.slice(0,1);
        this.departments.forEach((value, key) => {
          sessionStorage.userDepartmentID = value.id;
        });
        sessionStorage.ManagerGuid = userDetails.managerGuid;
        if(userDetails.userProfilePic!='' && userDetails.userProfilePic!=undefined){
          this.profileDisplayExist=true;
          if(this.baseUrl["API_ENDPOINTS"]["Storage"]=="azure"){
            this.profileDisplayPic = this.baseUrl["API_ENDPOINTS"]["BlobDownloadURL"]+userDetails.userProfilePic;
          }
          else{
            this.profileDisplayPic =
            API_ENDPOINTS.UMSAPI +
            "user/Download?filename=" +
            userDetails.userProfilePic;
          }
          sessionStorage.profileDisplayPic= this.profileDisplayPic;
          sessionStorage.profileDisplayExist= this.profileDisplayExist;
        }

         this.bindTopMenuList();
      },
      (error) => { 
        this.showLoading = false;
        console.log(error);
      }
    );
    }
  
    ProfileClick(){
      let ShowMenu="N";
      var applicationId="";
      this.applications.forEach((value, key) => {
        if(value.name == "User Management"){
          applicationId=value.applicationId;
        }
        if(value.name == "User Management" && value.active == "Y"){
          ShowMenu="Y";
          
        }
      });
      this.showLoading = true;
            this._facadeService.CreateSecurityToken().subscribe(
              (data) => {
                this.showLoading = false;
                if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
                  var accessToken = data.returnObject;
                  var clientId = sessionStorage.getItem('clientId')!=''&& sessionStorage.getItem('clientId')!=null&&sessionStorage.getItem('clientId')!=undefined?parseInt(sessionStorage.getItem('clientId')):0;
                  var IsDCC = sessionStorage.getItem('IsDCC')!=''&& sessionStorage.getItem('IsDCC')!=null&&sessionStorage.getItem('IsDCC')!=undefined?sessionStorage.getItem("IsDCC").toLowerCase() == "true"?true:false:false;
                  window.open(this.baseUrl["API_ENDPOINTS"]["UMSUI"]+'entrypoint?userguid='+this.userGuid+'&userId='+this.userId+
                  '&ShowMenu='+ShowMenu+"&access_token="+accessToken+'&IsDCC='+IsDCC
                  +'&bt='+sessionStorage.getItem('bt')+"&appliId="+applicationId+"&clientId="+clientId, '_blank');
                }
                else{
                  location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
                }
              },
              (error) => {
                this.showLoading = false;
                console.log(error);
              } 
            );
    }
    LogoutClick(){
      var userId = parseInt(sessionStorage.getItem('userId'));
          var appId= parseInt(sessionStorage.getItem('appliId'));
          var modelData ={
            "userId": userId,
            "applicationId": appId,
            "source":"logout"
          };
          this._facadeService.AddUserTracking(modelData).subscribe(
            (data) => {
              sessionStorage.clear();
              location.href =this.baseUrl["API_ENDPOINTS"]["UMSUI"];
            },
            (error) => {
              console.log(error);
            }
          );
     
    } 
    help(){
      this.router.navigate(["/helpguide"]);
  }

    // bindTopMenuList(){

    //   this.applications.forEach((value, key) => {
    //     if(value.name == "Project Management" && value.active == "Y"){
    //       this.TopMenuItemsList.push({ identifier: 'Project Management', iconPath: 'assets/gallery/pms.png', displayText: 'Projects'});
    //     }
    //     else if(value.name == "Product Management" && value.active == "Y"){
    //       this.TopMenuItemsList.push({ identifier: 'Product Management', iconPath: 'assets/gallery/pdm.png', displayText: 'Products'});
    //     }
    //     else if(value.name == "Change Management" && value.active == "Y"){
    //       this.TopMenuItemsList.push({ identifier: 'Change Management', iconPath: 'assets/gallery/cms.png', displayText: 'Change Requests'});
    //     }
    //     else if(value.name == "Document Management" && value.active == "Y"){
    //       this.TopMenuItemsList.push({ identifier: 'Document Management', iconPath: 'assets/gallery/dms.png', displayText: 'Documents'});
    //     }
    //     else if(value.name == "User Management" && value.active == "Y"){
    //       this.TopMenuItemsList.push({ identifier: 'User Management', iconPath: 'assets/gallery/ums.png', displayText: 'User Management'});
    //     }
    //     else if(value.name == "Process Change Notification" && value.active == "Y"){
    //       this.TopMenuItemsList.push({ identifier: 'Process Change Notification', iconPath: 'assets/gallery/pcn.png', displayText: 'PCN'});
    //     }
    //   });
    // }
  
   
    TopMenuClicks(menuItemclick,id){ 
      var IsDCC = sessionStorage.getItem('IsDCC')!=''&& sessionStorage.getItem('IsDCC')!=null&&sessionStorage.getItem('IsDCC')!=undefined?sessionStorage.getItem("IsDCC").toLowerCase() == "true"?true:false:false;
      this.showLoading = true;
            this._facadeService.CreateSecurityToken().subscribe(
              (data) => {
                this.showLoading = false;
                if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
                  var access_token = data.returnObject;
                  var clientId = sessionStorage.getItem('clientId')!=''&& sessionStorage.getItem('clientId')!=null&&sessionStorage.getItem('clientId')!=undefined?parseInt(sessionStorage.getItem('clientId')):0;
                  window.open(this.baseUrl["API_ENDPOINTS"][menuItemclick]+'entrypoint?userguid='
                  +this.userGuid+'&userId='+this.userId+'&IsDCC='+IsDCC+'&access_token='
                  +access_token+'&source=dashboard'
                  +'&bt='+sessionStorage.getItem('bt')+"&appliId="+id+"&clientId="+clientId, '_self');
                }
                else{
                  location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
                }
              },
              (error) => {
                this.showLoading = false;
                console.log(error);
              } 
            );
    }

bindTopMenuList(){
    this.TopMenuItemsList=[];
    this.applications.forEach((value, key) => {
      if(value.projectIdentifier!="ITMUI"){
        this.TopMenuItemsList.push({ id:value.applicationId, identifier: value.projectIdentifier, iconPath: value.iconPath, displayText: value.displayText});
      }
    });
  }

  
    ngOnDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
  }
