import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { FacadeService } from '../../facade/facade.service';
//import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import {Router, ActivatedRoute } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintcommonService } from 'src/app/services/printcommon.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  //userId = sessionStorage.getItem('userGuid');
  labels: number[] = [];
  data: number[] = [];
  timelineSubTitle: any;
  applications = JSON.parse(sessionStorage.getItem('applications'));
  firstName = sessionStorage.getItem('firstName');
  departments = [];

  options: any;
  plugin: any;
  today: any;
  Timeline: any = [];
  TimelineOptions: any;//TimelineOptions[];
  selectedTimelineOption: any;//TimelineOptions;
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  showLoading:boolean;
  dashBoardCounts:any;
  issueChart:any;
  issueData:any=[];
  isTimelineDataAvailable:boolean;
  isChartDataAvailable:boolean;
  isPriorityChartDataAvailable:boolean;

  priorityChart:any;
  dashBoardCountsReportedOpen:Int16Array;
  dashBoardCountsReportedInProgress:Int16Array;
  dashBoardCountsReportedInReview:Int16Array;
  dashBoardCountsAssignedOpen:Int16Array;
  dashBoardCountsAssignedInProgress:Int16Array;
  dashBoardCountsProductsReported:Int16Array;
  dashBoardCountsProductsAssigned:Int16Array;

  chartModel: any;
    
  profileDisplayPic:any;
  profileDisplayExist:boolean;
  fileName:any;
  piedata:any;
  userDetails:any;

  priorityPiedata:any;
  priorityChartlabels: number[] = [];
  priorityChartdata: number[] = [];
  
  Data:any;

  userGuid = sessionStorage.getItem('userGuid');
 
  constructor(
    private _facadeService: FacadeService,public printService: PrintcommonService,
    //private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }

  ngOnInit(): void {
    this.TimelineOptions = [
      { name: 'Week',value: 7 },
      { name: '2 Weeks',value: 14 },
      { name: '3 Weeks',value: 21 },
      { name: 'Month',value: 30 },
    ];

    this.cols = [
      { field: 'issueCode', header: 'Issue Id', dynamicPlaceHolder: 'Search by issue id' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by title' },
      { field: 'createdByUserName', header: 'Reported By', dynamicPlaceHolder: ' Search by reported by' },
      { field: 'assignToUserName', header: 'Assigned To', dynamicPlaceHolder: 'Search by assigned to' },
      { field: 'priority', header: 'Priority', dynamicPlaceHolder: 'Search by priority' },     
      { field: 'statusName', header: 'Status', dynamicPlaceHolder: 'Search by status' },       
      { field: 'productName', header: 'Product', dynamicPlaceHolder: 'Search by product' }    
    ];

  this.piedata = {
    labels: ['New', 'In-Progress', 'Resolved', 'In-Review', 'Closed'],
    datasets: [
      {
        data: [20, 10, 15, 15, 40],
        backgroundColor: [
          '#FBC02D',
          '#2196F3',
          '#689F38',
          '#FF0000',
          '#607D8B',
          ],
        
      },
    ],
  };
  this.options = {
    legend: { display: true, position:'right', align:'center', 
    labels: { fontColor: "#7b7c7c",fontSize: 14}
  },
};

this.priorityPiedata = {
  labels: ['Low', 'Medium', 'High', 'Critical'],
  datasets: [
    {
      data: [20, 10, 15, 15],
      backgroundColor: [
        '#66cc33',
        '#ff9933',
        '#cc0000',
        '#FF0000'
        ],
      
    },
  ],
};
this.options = {
  legend: { display: true, position:'right', align:'center', 
  labels: { fontColor: "#7b7c7c",fontSize: 14}
},
};

  this.userGuid = this.userGuid.toUpperCase();
  
  this.getDashboardSummary();
  this.getChartData();
  this.getPriorityChartData();
  this.getDashboardTimeline(7);
  this.getDashboardTableData();
}

getUserDetails(){
  this.showLoading = true;
 this._facadeService.GetUserDetails(this.userGuid)
.subscribe(
   (data) => {
    this.showLoading = false;
  var userDetails = data.returnObject;
  sessionStorage.userId = userDetails.userId;
  sessionStorage.userGuid =  this.userGuid;
  this.applications = userDetails.userApplications;
  sessionStorage.applications = JSON.stringify(userDetails.userApplications);
  this.applications.forEach((value, key) => {
    if(value.name == "Issue Tracker")
        sessionStorage.applicationID = value.id;
  });
  sessionStorage.roles = JSON.stringify(userDetails.userRoles);
  sessionStorage.firstName= userDetails.firstName;
  this.firstName= userDetails.firstName;
  this.departments = userDetails.userDepartments;
  this.departments= this.departments.slice(0,1);
  this.departments.forEach((value, key) => {
    sessionStorage.userDepartmentID = value.id;
  });
  sessionStorage.ManagerGuid = userDetails.managerGuid;
  if(userDetails.userProfilePic!='' && userDetails.userProfilePic!=undefined){
    this.profileDisplayExist=true;
    this.profileDisplayPic = this.baseUrl["API_ENDPOINTS"]["BlobDownloadURL"]+userDetails.userProfilePic;
    sessionStorage.profileDisplayPic= this.profileDisplayPic;
    sessionStorage.profileDisplayExist= this.profileDisplayExist;
  }
  if(userDetails.userRoles!=null && userDetails.userRoles!="" && userDetails.userRoles!=undefined &&userDetails.userRoles.length>0){
    var dcc = userDetails.userRoles.find(m=>m.name=="DCC Admin");
     if(dcc!=null){
       sessionStorage.IsDCC=true;
     }
   }
},
(error) => { 
  this.showLoading = false;
  console.log(error);
}
);
}

getDashboardSummary() {
  this.showLoading = true;
  this._facadeService.getDashboardCounts(this.userGuid.toUpperCase()).subscribe(
    (data) => {
      this.dashBoardCounts = data.returnObject;
      this.dashBoardCountsReportedOpen=this.dashBoardCounts.reportedOpen;
      this.dashBoardCountsReportedInProgress=this.dashBoardCounts.reportedInProgress;
      this.dashBoardCountsReportedInReview=this.dashBoardCounts.reportedInReview;
      this.dashBoardCountsAssignedOpen=this.dashBoardCounts.assignedOpen;
      this.dashBoardCountsAssignedInProgress=this.dashBoardCounts.assignedInProgress;
      this.dashBoardCountsProductsReported=this.dashBoardCounts.productsReported;
      this.dashBoardCountsProductsAssigned=this.dashBoardCounts.productsAssigned;
      //this.showLoading = false;
      //console.log("dashboard count:"+JSON.stringify(this.dashBoardCounts));
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    } 
  );
}

getChartData() {
  this.showLoading = true;
  this._facadeService.getChartData(this.userGuid.toUpperCase()).subscribe(
    (res) => {
      for (let i = 0; i < res.returnObject.length; i++) {
        this.labels.push(res.returnObject[i].status);
      }
      for (let i = 0; i < res.returnObject.length; i++) {
        this.data.push(res.returnObject[i].count);
        if(res.returnObject[i].count>0){
          this.isChartDataAvailable = true;
        }
      }
      //console.log("Dash board chart data :: " + JSON.stringify(res.returnObject));
      this.issueChart = {
        labels: this.labels,
        datasets: [
          {
            data: this.data,
            backgroundColor: [
              '#FBC02D',
              '#2196F3',
              '#689F38',
              '#FF0000',
              '#607D8B',
              ],
          },
        ],
      };

      this.options = {
        legend: { display: true, position:'right', align:'center', 
        labels: { fontColor: "#7b7c7c",fontSize: 14}
      },
    }
    //this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

getPriorityChartData() {
  this.showLoading = true;
  this._facadeService.getPriorityChartData(this.userGuid.toUpperCase()).subscribe(
    (res) => {
      for (let i = 0; i < res.returnObject.length; i++) {
        this.priorityChartlabels.push(res.returnObject[i].status);
      }
      for (let i = 0; i < res.returnObject.length; i++) {
        this.priorityChartdata.push(res.returnObject[i].count);
        if(res.returnObject[i].count>0){
          this.isPriorityChartDataAvailable = true;
        }
      }
      //console.log("Dash board chart data :: " + JSON.stringify(res.returnObject));
      this.priorityChart = {
        labels: this.priorityChartlabels,
        datasets: [
          {
            data: this.priorityChartdata,
            backgroundColor: [
              '#66cc33',
              '#ff9933',
              '#FF0000',
              '#990000',
              '#607D8B',
              ],
          },
        ],
      };

      this.options = {
        legend: { display: true, position:'right', align:'center', 
        labels: { fontColor: "#7b7c7c",fontSize: 14}
      },
    }
    //this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

 GetTimeline(data){
    this.getDashboardTimeline(data.value)
  }

getDashboardTimeline(type) {
  this.showLoading = true;
  this._facadeService.getDashboardTimeline(this.userGuid.toUpperCase(), type).subscribe(
    (data) => {
      this.showLoading = false;
      this.Timeline = data.returnObject;  
      if( this.Timeline.length>0) {  
        this.isTimelineDataAvailable = true;   
      var dict = []; // create an empty dictionary  
      for (let i = 0; i < this.Timeline.length; i++) { 
        let convertactiondate = new Date(this.Timeline[i].actionDate+'Z');  
        dict.push({  
          issueCode: this.Timeline[i].issueCode,
          title: this.Timeline[i].title,
          subTitle: this.Timeline[i].subTitle,
          icon: this.Timeline[i].icon, 
          actionBy: this.Timeline[i].actionBy,
          actionDate: new Date(this.Timeline[i].actionDate+'Z'),
          issueGuid: this.Timeline[i].issueGuid,
          statusId: this.Timeline[i].statusId,
          subTitleMod: this.sanitizer.bypassSecurityTrustHtml("The Issue " + '<a style="cursor:pointer;">' + this.Timeline[i].issueCode + '</a>' +" "+this.Timeline[i].subTitle+ " by "+ this.Timeline[i].actionByUserName + " on " +convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString())  
      });        
      }        
      //console.log("Time Line subtitle modified data Data :: " + JSON.stringify(dict));
      this.Timeline = dict;  
    } 
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

issueDetails(rowdata){   
    location.href = this.baseUrl["API_ENDPOINTS"]["ITMUI"]+'editissue?issueId='+rowdata.issueGuid; 
}

issue(rowdata){  
  location.href = this.baseUrl["API_ENDPOINTS"]["ITMUI"]+'editissue?issueId='+rowdata.issueGuid; 
}

print() {
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,'Recent Issues','trSearch',true);
}

exportExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx : 90 },{ wpx : 500 },{ wpx : 100 },{ wpx : 110 },{ wpx : 90 },{ wpx : 90 },{ wpx : 80 }];
    /* save to file */
    XLSX.writeFile(wb, "Recent Issues.xlsx");
}

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['H1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}

getDashboardTableData() {
  this.issueData=[];
  this.showLoading = true;
  this._facadeService.GetDashboardTableData30Days(this.userGuid.toUpperCase()).subscribe(
    (data) => {
      //this.showLoading = false;
      console.log(data);
      if(data.returnObject!=null){
        data.returnObject.forEach((value, key) => {
         let convertactiondate = new Date(value.createdAt+'Z') 
          value.createdDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
           this.issueData.push(value);
        });
      }
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

ReportedTileClick(status){
  location.href = this.baseUrl["API_ENDPOINTS"]["ITMUI"]+'myissues?status='+status;
}
AssignedTileClick(status){
  location.href = this.baseUrl["API_ENDPOINTS"]["ITMUI"]+'assignedtome?status='+status;
}

ProductReportedTileClick(){
  location.href = this.baseUrl["API_ENDPOINTS"]["ITMUI"]+'myissues?isProductRelated=1';
}

ProductAssignedTileClick(){
  location.href = this.baseUrl["API_ENDPOINTS"]["ITMUI"]+'assignedtome?isProductRelated=1';
}

}
