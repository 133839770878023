<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-grid p-fluid p-formgrid" >
                        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                            <label for="city"> From</label>
                            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedFromDate"></p-calendar>
                            <!-- <small class="p-invalid">Date Error</small> -->
                        </div>
                        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                            <label for="city"> To</label>
                            <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="selectedToDate"></p-calendar>
                            <!-- <small class="p-invalid">Date Error</small> -->
                        </div>
                        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                            <label for="Department">Status</label>
                            <p-multiSelect [options]="status" [(ngModel)]="selectedStatus"
                                (onChange)="selectedStatusChange($event)" class="multiselect-custom"
                                defaultLabel="Select Status" autoWidth="false">
                                <ng-template let-value pTemplate="selectedItems">
                                    <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                                        <div>option.name</div>
                                    </div>
                                    <div *ngIf="!selectedMulti || selectedMulti.length === 0"
                                        class="country-placeholder">
                                        Select Status
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                            <label for="title">Issue Title</label>
                            <input type="text" pInputText placeholder="Issue Title" [(ngModel)]="issueTitle" />
                            <!-- <small class="p-invalid">Title Error</small> -->
                        </div>
                        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                            <label for="Product">Product</label>

                            <p-autoComplete [(ngModel)]="selectedProduct" [suggestions]="filteredProduct"
                                (completeMethod)="filterProducts($event)" field="productName" [dropdown]="true">
                                <ng-template>
                                    <div>
                                        <div>{{ filteredProduct.productName }}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>             
                <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                    <a (click)="getIssueSearchData()">
                        <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search"
                            class="p-button-raised p-button-primary"
                            style="width:100%; margin-top: 25px;"></button>
                    </a>
                </div>
            </div>
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%">Issue Search</h5>
                <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                    <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
                        tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                        style="float:right;height:25px; width:25px;"></button>
                    <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()"
                        pTooltip="Download" tooltipPosition="top"
                        class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style="float:right; height:25px; width:25px;"></button>
                </span>
            </div>
            <div class="invoice invoice-header" id="invoice-content">
                <p-table  (sortFunction)="customSort($event)" [customSort]="true" #dt [value]="issuesData" id="excel-table" [columns]="cols" [scrollable]="true" scrollHeight="250px"
                    class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                    dataKey="id"
                    [globalFilterFields]="['issueCode','title', 'reportedByUserName','dueDate', 'productName','statusName','priority']">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'issueCode': i == 0, 'title': i == 1, 'reportedby': i == 2, 'assignto': i == 3, 
                            'dueDate': i == 4, 'product': i == 5, 'statusName': i == 6 , 'priority': i == 7}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th style="width:80px;">Actions</th>
                        </tr>
                        <tr id="trSearch">
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="dt.filter($event.target.value, col.field, 'contains')"
                                    style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                <td *ngIf="i == 0" style="text-align: left;padding-left:15px;  width: 120px;">
                                    {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: left;padding-left:15px;  width: 300px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 2" style="text-align: left;padding-left:15px;  width: 120px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 3" style="text-align: left;padding-left:15px;  width: 120px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;  width: 100px;">
                                    {{rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 5" style="text-align: left;padding-left:15px;  width: 200px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 6" style="text-align: left;padding-left:15px;  width: 120px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 7" style="text-align: left;padding-left:15px;  width: 80px;">
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                            <td style="text-align: center;width:80px;">
                                <a (click)="ViewMyIssue(rowData)"> <button pButton type="button" icon="pi pi-eye"
                                        pTooltip="view" tooltipPosition="left" class="p-button-raised p-button-primary"
                                        style="width:25px; height:25px;text-align:center;"></button>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="7" style="text-align: left;">No records found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>